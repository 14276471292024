import React, { useMemo, useState } from "react"

import { Print } from "@mui/icons-material"
import {
  Button,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getPrizeDeliveries } from "src/api/prize-deliveries"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeDailyPlans } from "src/api/prize-plans"
import { getPrizeBoothSales } from "src/api/prize-sales"
import { BackButton } from "src/components/atoms/BackButton"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { DialogTitleWithClose } from "src/components/molecules/DialogTitleWidthClose"
import { DatePicker } from "src/components/organisms/DatePicker"
import {
  FloorMapPointBoxProps,
  FloorMapPointBox,
  FloorMapBox,
  PrizeDailySalesFloorMapPointBox,
} from "src/components/organisms/FloorMapBox"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  convertPrizePlanWithSalesFloorMapPoint,
  PrizePlanWithSaleFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import {
  calcPrizeDailySales,
  PrizeDailySale,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { prizeDailyFloorMapDateLabelState } from "src/recoil"
import { getRatioLabel, roundNearest100 } from "src/utils"

export const PrizeDailySalesFloorMap: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const [datePickerDateLabel, setDatePickerDateLabel] = useRecoilState(
    prizeDailyFloorMapDateLabelState,
  )

  return (
    <MainContentLayout
      title="フロアマップ"
      renderFilter={() => (
        <Stack
          sx={(theme) => ({
            gap: 2,
            flexDirection: "row",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Stack sx={{ flex: 1 }}>
            <DatePicker
              value={datePickerDateLabel}
              onChange={setDatePickerDateLabel}
              hideTodayButton
            />
          </Stack>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Print />}
            sx={{ whiteSpace: "nowrap" }}
            onClick={() =>
              navigate(
                `/arcades/${arcadeCd}/prizes/sales/daily/floorMap/printSettings`,
              )
            }
          >
            印刷設定
          </Button>
        </Stack>
      )}
      renderContent={() => <PrizeDailySalesFloorMapInner />}
    />
  )
}

const PrizeDailySalesFloorMapInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const datePickerDateLabel = useRecoilValue(prizeDailyFloorMapDateLabelState)

  const floorMapReturn = useResource({
    subject: "フロアマップの取得",
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getPrizeFloorMap:${arcadeCd}`,
  }).resource
  const floorMapPoints = floorMapReturn?.data.floorMapPoints

  const prizeDailyPlansReturn = useResource({
    subject: "デイリー入替計画の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDailyPlans(arcadeCd, {
            from: datePickerDateLabel,
            to: datePickerDateLabel,
          })
      : undefined,
    recoilKey: `getPrizeDailyPlans:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}`,
  }).resource
  const prizeDailyPlans = useMemo(
    () => prizeDailyPlansReturn?.data.plans ?? [],
    [prizeDailyPlansReturn?.data.plans],
  )

  const prizeDeliveriesReturn = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeDeliveries(arcadeCd, {
            from: datePickerDateLabel,
            to: datePickerDateLabel,
          })
      : undefined,
    recoilKey: `getPrizeDeliveries:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}`,
  }).resource
  const prizeDeliveries = useMemo(
    () => prizeDeliveriesReturn?.data.deliveries || [],
    [prizeDeliveriesReturn],
  )

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeBoothSales(arcadeCd, {
            from: datePickerDateLabel,
            to: datePickerDateLabel,
          })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${datePickerDateLabel}:${datePickerDateLabel}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const prizeDailySales = useMemo(() => {
    const sortFn = (a: PrizeDailySale, b: PrizeDailySale) => {
      return (a.sales || 0) - (b.sales || 0)
    }

    return calcPrizeDailySales(
      prizeBoothSales,
      prizeDailyPlans,
      prizeDeliveries,
      () => true,
      sortFn,
    )
  }, [prizeBoothSales, prizeDailyPlans, prizeDeliveries])

  const [selectedPoint, setSelectedPoint] =
    useState<PrizePlanWithSaleFloorMapPoint>()

  const onClickPoint = (point: PrizePlanWithSaleFloorMapPoint) => {
    if (point.plans.length === 0) return

    setSelectedPoint(point)
  }

  const getFloorMapPointBox = ({ point }: FloorMapPointBoxProps) => (
    <FloorMapPointBox
      point={point}
      onClickPoint={(point) =>
        onClickPoint(point as PrizePlanWithSaleFloorMapPoint)
      }
      sx={{
        backgroundColor: "transparent",
        cursor: "pointer",
        overflow: "hidden",
      }}
    >
      <PrizeDailySalesFloorMapPointBox key={point.id} point={point} />
    </FloorMapPointBox>
  )

  const planFloorMapPoints = useMemo(
    () =>
      (floorMapPoints || []).map((p) =>
        convertPrizePlanWithSalesFloorMapPoint(
          p,
          prizeDailyPlans ?? [],
          prizeDailySales.items,
        ),
      ),
    [floorMapPoints, prizeDailyPlans, prizeDailySales.items],
  )

  return (
    <>
      {floorMapPoints && (
        <FloorMapBox
          floorMapPoints={planFloorMapPoints}
          getFloorMapPointBox={getFloorMapPointBox}
        />
      )}
      {selectedPoint && (
        <PrizeDailySalesFloorMapModal
          showModal={!!selectedPoint}
          selectedPoint={selectedPoint}
          onClose={() => setSelectedPoint(undefined)}
        />
      )}
    </>
  )
}

type PrizeDailySalesFloorMapModalProps = {
  showModal: boolean
  selectedPoint: PrizePlanWithSaleFloorMapPoint
  onClose: () => void
}

export const PrizeDailySalesFloorMapModal: React.FC<
  PrizeDailySalesFloorMapModalProps
> = ({ showModal, selectedPoint, onClose }) => {
  const { plans, prizeDailySale } = selectedPoint

  return (
    <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
      <DialogContent>
        {/* TODO: このコンポーネントで定義されたスタイルとデザインが違う */}
        <DialogTitleWithClose onClose={onClose} sx={{ p: 0 }} />

        <Stack gap={3}>
          <Typography variant="h1">{plans[0]?.boothName}</Typography>

          {plans.map((plan, i) => (
            <Stack key={plan.id} gap={2}>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    景品名
                  </Typography>
                </Stack>
                <Stack flex={2}>
                  <Typography>{plan.prize.prizeName}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    景品CD
                  </Typography>
                </Stack>
                <Stack flex={2}>
                  <Typography>{plan.prize.prizeCd}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    設定
                  </Typography>
                </Stack>
                <Stack flex={2}>
                  <Typography>
                    {plan.setting !== "" ? plan.setting : "-"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    単価
                  </Typography>
                </Stack>
                <Stack flex={2}>
                  <Typography>{plan.prize.unitPriceJpy}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    1個獲得金額
                  </Typography>
                </Stack>
                <Stack flex={2}>
                  <Typography>
                    {prizeDailySale?.monthlyPlan?.expectedCapturePrice
                      ? roundNearest100(
                          prizeDailySale?.monthlyPlan?.expectedCapturePrice,
                        ).toLocaleString()
                      : "-"}
                    円
                  </Typography>
                </Stack>
              </Stack>
              {plans.length - 1 !== i && <Divider />}
            </Stack>
          ))}

          <Divider />

          <Stack direction="row">
            <Stack flex={1}>
              <Typography variant="subtitle1">
                {dayjs(plans[0].recordedAt).format("D日")}
              </Typography>
            </Stack>

            <Stack flex={2} gap={3}>
              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    売上
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <Typography>
                    {prizeDailySale?.sales !== undefined
                      ? `${prizeDailySale.sales.toLocaleString()}円`
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack flex={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    P/O
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <Typography>
                    {prizeDailySale
                      ? `${getRatioLabel(prizeDailySale.payoutRate)}`
                      : "-"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <CustomDialogActions>
        <BackButton onClick={() => onClose()}>戻る</BackButton>
      </CustomDialogActions>
    </CustomDialog>
  )
}
