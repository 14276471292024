import React, { useMemo, useState, useEffect } from "react"

import { LoadingButton } from "@mui/lab"
import { Grid, TextField, Autocomplete } from "@mui/material"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"

import { getMaterialInventoryGroups } from "src/api/material-inventory-group"
import { deleteMaterialShelf, putMaterialShelf } from "src/api/material-shelves"
import { getMaterialStorage } from "src/api/material-storages"
import {
  PutMaterialShelvesRequest,
  MaterialShelf,
  MaterialStorage,
} from "src/api/models"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { temporaryStorageName } from "src/domains/materials/materialOperationStocksRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"

type FormType = Pick<MaterialShelf, "name"> & {
  inventoryGroupName?: string
}

export const InventoryMaterialEditShelf = () => {
  const { arcadeCd, storageId, shelfId } = useParams()
  const { resource } = useResource({
    subject: "保管場所情報の取得",
    fetch:
      arcadeCd && storageId
        ? () => getMaterialStorage(arcadeCd, Number(storageId))
        : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}:${storageId}`,
  })
  const { storage, shelves, shelf } = useMemo(() => {
    const { storage, shelves } = resource?.data || {}
    return {
      storage,
      shelves,
      shelf: shelves?.find((shelf) => shelf.id.toString() === shelfId),
    }
  }, [resource, shelfId])

  return (
    <MainContentLayout
      title={storage && shelf ? storage.name + " " + shelf.name : ""}
      renderContent={() => (
        <InventoryMaterialEditShelfForm
          storage={storage}
          shelves={shelves}
          shelf={shelf}
        />
      )}
      backButtonLabel="保存せずに戻る"
    />
  )
}

type InventoryMaterialEditShelfFormProps = {
  storage?: MaterialStorage
  shelves?: MaterialShelf[]
  shelf?: MaterialShelf
}

const InventoryMaterialEditShelfForm: React.FC<
  InventoryMaterialEditShelfFormProps
> = ({ storage, shelves, shelf }) => {
  const { arcadeCd, storageId, shelfId } = useParams()
  const navigate = useNavigate()

  const inventoryGroups = useResource({
    subject: "棚卸グループの取得",
    fetch: arcadeCd
      ? () => getMaterialInventoryGroups(arcadeCd, { placement: "storage" })
      : undefined,
    recoilKey: `getMaterialInventoryGroups:${arcadeCd}:storage`,
  }).resource?.data.groups
  const inventoryGroupNames = useMemo(
    () => inventoryGroups?.map((g) => g.groupName) || [],
    [inventoryGroups],
  )

  const shelfNames = useMemo(() => {
    return (shelves || []).map((shelf) => shelf.name)
  }, [shelves])

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const {
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<FormType>()

  const value = {
    shelfName: useWatch({ control, name: "name" }),
  }

  const isOtherShelfName =
    shelf?.name === value.shelfName
      ? false
      : shelfNames.includes(value.shelfName)

  useEffect(() => {
    shelf && reset(shelf)
  }, [reset, shelf])

  useEffect(() => {
    const { groupName } = shelf?.inventoryGroup || {}
    if (groupName && inventoryGroupNames.includes(groupName)) {
      setValue("inventoryGroupName", groupName)
    }
  }, [shelf, setValue, inventoryGroupNames])

  const { submitPromises } = useSubmitting()
  const onSubmit: SubmitHandler<FormType> = async ({
    name,
    inventoryGroupName,
  }) => {
    const numStorageId = Number(storageId)
    const numShelfId = Number(shelfId)
    if (!arcadeCd || isNaN(numStorageId)) return
    const request: PutMaterialShelvesRequest = {
      id: numShelfId,
      name,
      inventoryGroupName: inventoryGroupName ?? "",
    }

    await submitPromises([
      {
        subject: "棚の変更",
        showSuccessMessage: true,
        promise: async () => {
          await putMaterialShelf(arcadeCd, numStorageId, request)
          return navigate(-1)
        },
      },
    ])
  }

  const onDelete = async () => {
    const numStorageId = Number(storageId)
    const numShelfId = Number(shelfId)
    if (!arcadeCd || isNaN(numStorageId) || isNaN(numShelfId)) return

    submitPromises([
      {
        subject: "棚の削除",
        showSuccessMessage: true,
        promise: async () => {
          await deleteMaterialShelf(arcadeCd, numStorageId, numShelfId)
          return navigate(-1)
        },
      },
    ])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DeleteModal
        onSubmit={onDelete}
        onClose={() => setDeleteModalOpen(false)}
        showModal={deleteModalOpen}
        isSubmitting={isSubmitting}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"name"}
            defaultValue={shelf?.name ?? ""}
            render={({ field }) => (
              <TextField
                label="棚名称"
                fullWidth
                sx={{ background: "white" }}
                {...field}
                error={isOtherShelfName}
                helperText={isOtherShelfName && "すでに存在する棚名です"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={"inventoryGroupName"}
            defaultValue={""}
            render={({ field }) => (
              <Autocomplete
                options={inventoryGroupNames}
                renderInput={(params: object) => (
                  <TextField
                    sx={{ background: "white" }}
                    label="棚卸グループ"
                    fullWidth
                    name={field.name}
                    {...params}
                    error={!!errors.inventoryGroupName?.message}
                    helperText={errors.inventoryGroupName?.message}
                  />
                )}
                ref={field.ref}
                value={field.value}
                onInputChange={(e, newValue) => {
                  field.onChange(newValue)
                }}
              />
            )}
          />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              disabled={
                storage?.name === temporaryStorageName || isOtherShelfName
              }
              loading={isSubmitting}
            >
              棚の変更を保存
            </LoadingButton>
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="error"
              fullWidth
              disabled={storage?.name === temporaryStorageName}
              loading={isSubmitting}
              onClick={() => setDeleteModalOpen(true)}
            >
              棚を削除
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
