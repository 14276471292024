import React, { useState, useMemo } from "react"

import { Tabs, Tab, Stack } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { getAmMachine } from "src/api/am-machines"
import {
  Material,
  MaterialMachinesElement,
  MaterialOperationMachineStock,
  MaterialMachineWarningEnum,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import {
  InventoryMaterialExecuteList,
  InventoryMaterialExecutePlacementListItem,
} from "src/components/organisms/materials/InventoryMaterialExecuteList"
import { inventoryMaterialExecuteMultipleSearchParamsState } from "src/components/organisms/materials/InventoryMaterialExecuteMultipleFilter"
import {
  InventoryMaterialExecuteMultiple,
  InventoryMaterialExecuteMultipleMenuItem,
} from "src/components/templates/InventoryMaterialExecuteMultiple"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import { useInventoryExecuteMachines } from "src/hooks/useInventoryExecuteMachines"
import { useInventoryExecuteMaterials } from "src/hooks/useInventoryExecuteMaterials"
import { useResource } from "src/hooks/useResource"
import { InventoryMaterialExecuteInMachineMenuTabParamsState } from "src/recoil/inventoryMaterials"

export const InventoryMaterialExecuteInMachineAmMachineDetails = () => {
  const { arcadeCd, amMachineNumber } = useParams()

  const { amMachineName } =
    useResource({
      subject: "AM機器詳細の取得",
      fetch:
        arcadeCd && amMachineNumber
          ? () => getAmMachine(arcadeCd, amMachineNumber)
          : undefined,
      recoilKey: `getAmMachine:${amMachineNumber}`,
    }).resource?.data.amMachine ?? {}

  return (
    <MainContentLayout
      title={amMachineName ?? ""}
      renderContent={() => <InventoryMaterialExecuteInMachineMenuInner />}
      disableBackButton
    />
  )
}

export type InventoryMaterialExecuteInMachineMenuTabParams =
  | "materials"
  | "materialMachines"

const InventoryMaterialExecuteInMachineMenuInner = () => {
  const [tab, setTab] = useRecoilState(
    InventoryMaterialExecuteInMachineMenuTabParamsState,
  )
  const [showTab, setShowTab] = useState(false)
  const tabs = [
    { key: "materials", name: "材料一覧" },
    { key: "materialMachines", name: "シリンダー一覧" },
  ]

  const onSelected = (isSelected: boolean) => {
    setShowTab(isSelected)
  }

  return (
    <>
      {!showTab && (
        <Stack>
          <Tabs
            value={tab}
            onChange={(_, value) => setTab(value)}
            scrollButtons={false}
          >
            {tabs.map((t) => (
              <Tab key={t.key} value={t.key} label={t.name} />
            ))}
          </Tabs>
        </Stack>
      )}
      <Stack mt={2}>
        {tab === "materials" && (
          <InventoryMaterialExecuteInMachineMaterialMenu
            onSelected={onSelected}
          />
        )}
        {tab === "materialMachines" && (
          <InventoryMaterialExecuteInMachineMaterialMachineMenu />
        )}
      </Stack>
    </>
  )
}

type MaterialListMap = Map<
  string,
  (MaterialOperationMachineStock & { material: Material })[]
>

type InventoryMaterialExecuteInMachineMaterialMenuProps = {
  onSelected: (isSelected: boolean) => void
}

const InventoryMaterialExecuteInMachineMaterialMenu: React.FC<
  InventoryMaterialExecuteInMachineMaterialMenuProps
> = ({ onSelected }) => {
  const { arcadeCd, amMachineNumber } = useParams()
  const setSearchParams = useRecoilValue(
    inventoryMaterialExecuteMultipleSearchParamsState,
  )

  const {
    filteredStocks,
    histories,
    refetchStatuses,
    refetchHistories,
    refetchStocks,
  } = useInventoryExecuteMaterials({
    arcadeCd: arcadeCd || "",
    amMachineNumber: amMachineNumber,
    searchParams: setSearchParams,
  })

  const refetch = () => {
    refetchStatuses()
    refetchHistories()
    refetchStocks()
  }

  const menuItems: InventoryMaterialExecuteMultipleMenuItem[] = useMemo(() => {
    const machineStockMap: MaterialListMap = new Map()
    filteredStocks.map((machineStock) => {
      const list =
        machineStockMap.get(machineStock.material.materialCd || "") || []
      list.push(machineStock)
      machineStockMap.set(machineStock.material.materialCd, list)
    })
    return Array.from(machineStockMap.values()).map((list) => {
      return {
        material: list[0].material,
        stocks: list.map((machineStock) => {
          const { amMachine, materialMachine } = machineStock
          return {
            placementName: getDisplayMaterialMachineName(
              amMachine,
              materialMachine,
              true,
            ),
            placementStockId: machineStock.machineStock.id,
            stock: machineStock.machineStock.stock,
            executedStock: 0,
          }
        }),
      }
    })
  }, [filteredStocks])

  const warningMaterialMachines =
    filteredStocks
      ?.filter(
        (stock) =>
          stock.materialMachine.warning !== MaterialMachineWarningEnum.None,
      )
      .map((stock) => {
        return {
          amMachine: stock.amMachine,
          materialMachine: stock.materialMachine,
        } as MaterialMachinesElement
      }) || []

  return (
    <InventoryMaterialExecuteMultiple
      placementType="in_machine"
      placementStocks={filteredStocks}
      menuItems={menuItems}
      histories={histories}
      warningMaterialMachines={warningMaterialMachines}
      onSelected={onSelected}
      onFinish={() => {
        onSelected(false)
        refetch()
      }}
    />
  )
}

const InventoryMaterialExecuteInMachineMaterialMachineMenu = () => {
  const { arcadeCd, amMachineNumber } = useParams()
  const navigate = useNavigate()

  const handleOnClick = ({
    materialMachineId,
    isAvailable,
  }: InventoryMaterialExecutePlacementListItem<"in_machine">) => {
    if (!isAvailable) return
    navigate(
      `/arcades/${arcadeCd}/inventory/materials/execute/machine/amMachines/${amMachineNumber}/${materialMachineId}`,
    )
  }

  const { placementListItems, materialMachineGroupNames, materialMachines } =
    useInventoryExecuteMachines({ amMachineNumber })

  const warningMaterialMachines =
    materialMachines?.filter(
      (materialMachine) =>
        materialMachine.materialMachine.warning !==
        MaterialMachineWarningEnum.None,
    ) || []

  return (
    <Stack gap={2}>
      <InventoryMaterialExecuteList
        placement={MaterialPlacementType.InMachine}
        groupNames={materialMachineGroupNames}
        listItems={placementListItems}
        warningMaterialMachines={warningMaterialMachines}
        handleOnClick={handleOnClick}
      />
      <BackButton>戻る</BackButton>
    </Stack>
  )
}
