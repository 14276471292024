import { TextField, Autocomplete } from "@mui/material"
import { useRecoilState } from "recoil"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"
import { FilterAccordion } from "src/components/molecules/FilterAccordion"
import {
  materialPlacementTypeNames,
  materialPlacementTypes,
} from "src/domains/materials/materialInventoryHistoriesRepository"
import {
  InventoryMaterialPlacementsSearchParams,
  Placement,
} from "src/pages/materials/placements/InventoryMaterialPlacements"
import { inventoryMaterialPlacementsSearchParamsState } from "src/recoil/inventoryMaterials"
import { generateFuzzyRegExp } from "src/utils"

export const InventoryMaterialPlacementsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialPlacementsSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み"
      defaultExpanded={!!(searchParams.placementType || searchParams.name)}
      formInputs={[
        {
          name: "placementType",
          label: "区分",
          render: ({ field, fieldState: { error } }) => (
            <Autocomplete
              options={materialPlacementTypes.map((placementType) => ({
                label: materialPlacementTypeNames[placementType],
                type: placementType,
              }))}
              defaultValue={
                materialPlacementTypes
                  .filter(
                    (placementType) =>
                      placementType === searchParams.placementType,
                  )
                  .map((placementType) => ({
                    label: materialPlacementTypeNames[placementType],
                    type: placementType,
                  }))[0]
              }
              renderOption={(props, option) => (
                <WordBreakAllList {...props} key={option.type}>
                  {option.label}
                </WordBreakAllList>
              )}
              onChange={(_, option) => field.onChange(option?.type)}
              isOptionEqualToValue={(option, value) =>
                option.type === value.type
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!error}
                  placeholder="棚／材料機械内"
                />
              )}
            />
          ),
        },
        {
          name: "name",
          label: "名前",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
      ]}
    />
  )
}

export const filterPlacements = (
  placements: Placement[],
  params: InventoryMaterialPlacementsSearchParams,
) => {
  const { name, placementType } = params

  return placements
    .filter((p) => (name ? generateFuzzyRegExp(name).test(p.name) : true))
    .filter((p) => (placementType ? p.placementType === placementType : true))
}
