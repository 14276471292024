import { Fragment, Suspense, useCallback, useMemo } from "react"

import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  Link,
  CircularProgress,
  Checkbox,
  Chip,
} from "@mui/material"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"

import { Prize, PrizeStock } from "src/api/models"
import { getPrizeTonePrizeChanges } from "src/api/prize-tone-prize-changes"
import { getPrizeToneStocks } from "src/api/prize-tone-stocks"
import changeIcon from "src/assets/icon_change_large.png"
import { BackButton } from "src/components/atoms/BackButton"
import { CardItemNameBox } from "src/components/molecules/CardTableCells"
import {
  filterPrizeNewArrivals,
  PrizeNewArrivalsFilter,
} from "src/components/organisms/PrizeNewArrivalsFilter"
import { useResource } from "src/hooks/useResource"
import {
  hidePrizeNewArrivalsBeforeYesterdayState,
  prizeNewArrivalSearchParamsState,
} from "src/recoil"
import {
  formatApiDate,
  getJpDateLabel,
  getJpTodayDayjs,
  getToday,
  isValidDateLabel,
} from "src/utils"

dayjs.extend(isSameOrAfter)

const invalidArrivalDateLabel = "着荷日未定"

// NOTE: 着荷予定景品の表示には、在庫レコードを利用する
export type PrizeNewArrival = PrizeStock
interface PrizeNewArrivalsPerDate {
  [key: string]: PrizeNewArrival[]
}

export interface PrizeNewArrivalSearchParams {
  prizeName?: Prize["prizeName"]
  makerName?: Prize["makerName"]
  ipName?: Prize["ipName"]
}

export const PrizeNewArrivals: React.FC = () => (
  <Suspense
    fallback={
      <Box p={2} sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    }
  >
    <PrizeNewArrivalsInner />
  </Suspense>
)

const PrizeNewArrivalsInner: React.FC = () => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()

  const prizeNewArrivals = useResource({
    subject: "着荷予定景品一覧の取得",
    fetch: arcadeCd ? () => getPrizeToneStocks(arcadeCd) : undefined,
    recoilKey: `getStocks:${arcadeCd}`,
    useCache: true,
  }).resource?.data?.stocks
  const prizeChanges = useResource({
    subject: "当日入れ替える景品情報の取得",
    fetch: arcadeCd
      ? () => getPrizeTonePrizeChanges(arcadeCd, getToday())
      : undefined,
    recoilKey: `getPrizeChanges:${arcadeCd}:${getToday()}`,
    useCache: true,
  }).resource?.data?.prizeChanges
  const searchParams = useRecoilValue(prizeNewArrivalSearchParamsState)
  const [hideBeforeYesterday, setHideBeforeYesterday] = useRecoilState(
    hidePrizeNewArrivalsBeforeYesterdayState,
  )

  const prizeNewArrivalsPerDate = useMemo(() => {
    const filteredPrizeNewArrivals = filterPrizeNewArrivals(
      prizeNewArrivals ?? [],
      searchParams,
    )
    const newArrivals: PrizeNewArrivalsPerDate = {}
    // NOTE: 着荷予定日 willArriveAt が存在するレコードを、当日以降の日付でグルーピングする
    filteredPrizeNewArrivals
      .filter((p) => !!p.prize.prizeName)
      .filter((p) => !!p.prizeOrders)
      .forEach((p) => {
        p.prizeOrders?.forEach((o) => {
          const arrivalDate = formatApiDate(o.willArriveAt)
          const today = getJpTodayDayjs()
          const arrivalDateDayjs = dayjs(arrivalDate)

          // 当月のみ
          if (arrivalDateDayjs.isBefore(today.startOf("month"))) return

          // 今日が月末の7日以前で、arrivalDateが翌月以降の場合は弾く
          if (
            today.isBefore(
              today.endOf("month").startOf("date").subtract(6, "day"),
            ) &&
            arrivalDateDayjs.isSameOrAfter(
              today.startOf("month").add(1, "month"),
            )
          )
            return

          // 今日が月末の7日内で、arrivalDateが翌々月以降の場合は弾く
          if (
            today.isSameOrAfter(
              today.endOf("month").startOf("date").subtract(6, "day"),
            ) &&
            arrivalDateDayjs.isSameOrAfter(
              today.startOf("month").add(2, "month"),
            )
          )
            return

          newArrivals[arrivalDate] = [...(newArrivals[arrivalDate] || []), p]
        })
      })
    return newArrivals
  }, [prizeNewArrivals, searchParams])

  const hasPrizeChanges = useCallback(
    (newArrival: PrizeNewArrival) =>
      prizeChanges?.some(({ currentDay }) => {
        return (
          currentDay.date === getToday() &&
          currentDay.prizeCd === newArrival.prize.prizeCd
        )
      }) ?? false,
    [prizeChanges],
  )

  return (
    <>
      <Box
        sx={{
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h1">
            着荷予定景品一覧
          </Typography>

          {prizeNewArrivals && (
            <Box sx={{ m: 1, mb: 2 }}>
              <PrizeNewArrivalsFilter />
            </Box>
          )}

          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            前日以前の景品を表示しない
            <Checkbox
              checked={hideBeforeYesterday}
              onClick={() => setHideBeforeYesterday(!hideBeforeYesterday)}
            />
          </Box>

          <Grid container>
            {Object.keys(prizeNewArrivalsPerDate)
              .filter((arrivalDate) =>
                hideBeforeYesterday
                  ? dayjs(arrivalDate).isSameOrAfter(
                      getJpTodayDayjs().startOf("date"),
                    )
                  : true,
              )
              .sort()
              .map((arrivalDate) => (
                <Fragment key={arrivalDate}>
                  <Grid item xs={12} sx={{ fontWeight: "bold" }}>
                    {isValidDateLabel(arrivalDate)
                      ? getJpDateLabel(arrivalDate)
                      : invalidArrivalDateLabel}
                  </Grid>
                  {prizeNewArrivalsPerDate[arrivalDate].map(
                    (prizeNewArrival) => (
                      <Grid item key={prizeNewArrival.prize.prizeCd} xs={12}>
                        <PrizeNewArrivalCard
                          arrivalDate={arrivalDate}
                          prizeNewArrival={prizeNewArrival}
                          hasPrizeChanges={hasPrizeChanges(prizeNewArrival)}
                        />
                      </Grid>
                    ),
                  )}
                </Fragment>
              ))}
          </Grid>

          <Box pt={2}>
            <BackButton onClick={() => navigate(`/arcades/${arcadeCd}/prizes`)}>
              戻る
            </BackButton>
          </Box>
        </Container>
      </Box>
    </>
  )
}

interface PrizeNewArrivalCardProps {
  prizeNewArrival: PrizeNewArrival
  hasPrizeChanges: boolean
  arrivalDate: string
}

const PrizeNewArrivalCard: React.FC<PrizeNewArrivalCardProps> = ({
  prizeNewArrival,
  hasPrizeChanges,
  arrivalDate,
}: PrizeNewArrivalCardProps) => {
  const isAccepted = useMemo(() => {
    const ordersOfArrivalDate = (prizeNewArrival.prizeOrders || []).filter(
      (o) => dayjs.tz(o.willArriveAt, "Asia/Tokyo").isSame(dayjs(arrivalDate)),
    )
    return (
      ordersOfArrivalDate.length > 0 &&
      ordersOfArrivalDate?.every((o) => o.accepted === true)
    )
  }, [arrivalDate, prizeNewArrival.prizeOrders])

  const { arcadeCd } = useParams()
  return (
    <Card
      sx={{
        m: 1,
        p: 1.5,
      }}
    >
      <Typography
        variant="body2"
        component={Box}
        sx={{ display: "flex", flexDirection: "row" }}
      >
        <CardItemNameBox>
          <Link
            component={RouterLink}
            to={`/arcades/${arcadeCd}/prizes/search/toneStocks/${encodeURIComponent(
              prizeNewArrival.prize.prizeCd,
            )}`}
            underline="none"
          >
            {prizeNewArrival.prize.prizeName}
          </Link>
        </CardItemNameBox>
        {isAccepted && (
          <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <Chip label="検収済み" size="small" />
          </Box>
        )}
        {hasPrizeChanges && (
          <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
            <img src={changeIcon} style={{ width: 32, height: 32 }} />
          </Box>
        )}
      </Typography>
    </Card>
  )
}
