import { MenuItem, Select, TextField } from "@mui/material"
import { atom, useRecoilState } from "recoil"

import { FilterAccordion } from "src/components/molecules/FilterAccordion"

const inventoryMaterialExecuteInMachineAmMachinesSortByLabels = {
  amMachineNameAsc: "AM機器名称（昇順）",
  amMachineNameDesc: "AM機器名称（降順）",
} as const

export const inventoryMaterialExecuteInMachineAmMachinesSearchParamsState =
  atom<{
    groupName: string
    amMachineName: string
    sortBy:
      | ""
      | keyof typeof inventoryMaterialExecuteInMachineAmMachinesSortByLabels
  }>({
    key: "inventoryMaterialExecuteInMachineAmMachinesSearchParamsState",
    default: {
      groupName: "",
      amMachineName: "",
      sortBy: "",
    },
  })

type Props = {
  gorupNames: string[]
}

export const InventoryMaterialExecuteInMachineAmMachinesFilter: React.FC<
  Props
> = ({ gorupNames }) => {
  const [searchParams, setSearchParams] = useRecoilState(
    inventoryMaterialExecuteInMachineAmMachinesSearchParamsState,
  )

  return (
    <FilterAccordion
      searchParams={searchParams}
      setSearchParams={setSearchParams}
      accordionLabel="絞り込み・並び替え"
      defaultExpanded={
        !!(
          searchParams.groupName ||
          searchParams.amMachineName ||
          searchParams.sortBy
        )
      }
      formInputs={[
        {
          name: "groupName",
          label: "棚卸グループ",
          render: ({ field }) => (
            <Select {...field}>
              <MenuItem value="" sx={{ height: 36 }} />
              {gorupNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          ),
        },
        {
          name: "amMachineName",
          label: "AM機器名称",
          render: ({ field, fieldState: { error } }) => (
            <TextField {...field} error={!!error} helperText={error?.message} />
          ),
        },
        {
          name: "sortBy",
          label: "並び替え",
          render: ({ field }) => (
            <Select {...field}>
              <MenuItem key="" value="" sx={{ height: 36 }} />
              {Object.entries(
                inventoryMaterialExecuteInMachineAmMachinesSortByLabels,
              ).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          ),
        },
      ]}
    />
  )
}
