import { ImmutableRouteKey } from "@remix-run/router/utils"
import { IndexRouteObject } from "react-router-dom"

type RequireOne<T, Key = keyof T> = Exclude<
  {
    [K in keyof T]: K extends Key ? Omit<T, K> & Required<Pick<T, K>> : never
  }[keyof T],
  undefined
>

const sleep = (msec: number) =>
  new Promise((resolve) => setTimeout(resolve, msec))

export const dynamicImportWithRetry = async (
  importFn: () => Promise<
    RequireOne<Omit<IndexRouteObject, ImmutableRouteKey>>
  >,
): Promise<RequireOne<Omit<IndexRouteObject, ImmutableRouteKey>>> => {
  const retryImport = async (
    retriesLeft = 5,
    interval = 1000,
  ): Promise<ReturnType<typeof importFn>> => {
    try {
      return await importFn()
    } catch (error) {
      if (retriesLeft === 1) throw error

      await sleep(interval)
      return await retryImport(retriesLeft - 1, interval)
    }
  }

  try {
    return await retryImport()
  } catch {
    location.reload()
    await sleep(30000)
    throw new Error("Failed to reload after chunk load failure")
  }
}
