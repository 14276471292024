import { useState } from "react"

import { useSetRecoilState } from "recoil"

import { Csv } from "src/api/models"
import { snackbarErrorMessageState } from "src/recoil"
import { parseCsvFile } from "src/utils/parseCsvFile"

type UseUploadCsvFilesProps = {
  requiredColumns: readonly string[]
  allowEmptyRows?: boolean
}

export const useUploadCsvFiles = ({
  requiredColumns,
  allowEmptyRows = false,
}: UseUploadCsvFilesProps) => {
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const [uploadedCsvSet, setUploadedCsvSet] = useState<Map<string, Csv>>(
    new Map(),
  )
  const [uploadedCsvs, setUploadedCsvs] = useState<Csv[]>()
  const [uploadedFiles, setUploadedFiles] = useState<File[]>()

  const parseCsv = async (files: File[]) => {
    const csvs: Csv[] = []
    await Promise.all(
      files.map(async (file) => {
        if (!file) {
          throw new Error("ファイルが取得できませんでした")
        }

        const { headerRow, rows } = await parseCsvFile(file)

        const missingColumn = requiredColumns.find(
          (column) => !headerRow.columns.includes(column),
        )
        if (missingColumn) {
          throw new Error(
            `CSVファイルから「${missingColumn}」列を検出できませんでした`,
          )
        }
        if (!allowEmptyRows && rows.length === 0) {
          throw new Error(`CSVファイルのデータが空です`)
        }

        csvs.push({
          headerRow,
          rows,
        })
      }),
    )
    return csvs
  }

  const onUploadCsvs = async (files: File[]) => {
    try {
      const newFileMap = new Map<string, File>()
      uploadedFiles?.forEach((file) => newFileMap.set(file.name, file))
      files.forEach((file) => newFileMap.set(file.name, file))
      const newFileArray = Array.from(newFileMap.values())

      const csvs = await parseCsv(newFileArray)

      setUploadedCsvs(csvs.length === 0 ? undefined : csvs)
      setUploadedFiles(newFileArray)
      setUploadedCsvSet(
        new Map<string, Csv>(csvs.map((csv, i) => [newFileArray[i].name, csv])),
      )
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
      }
    }
  }

  const resetUploadedCsvs = () => setUploadedCsvs(undefined)

  const deleteFile = async (fileName: string) => {
    if (!uploadedFiles) return
    const newFiles = Array.from(uploadedFiles).filter(
      (file) => file.name !== fileName,
    )
    const csvs = await parseCsv(newFiles)
    setUploadedCsvs(csvs.length === 0 ? undefined : csvs)
    setUploadedFiles(newFiles.length === 0 ? undefined : newFiles)
    setUploadedCsvSet(
      new Map<string, Csv>(csvs.map((csv, i) => [newFiles[i].name, csv])),
    )
  }

  return {
    uploadedCsvs,
    uploadedFiles,
    uploadedCsvSet,
    onUploadCsvs,
    deleteFile,
    resetUploadedCsvs,
  }
}
