import { useState, useMemo, useCallback } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Box,
  Typography,
  Button,
  Switch,
  TableHead,
  TableRow,
  DialogContent,
  Stack,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import { getMaterialMachines } from "src/api/material-machines"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { patchMaterialPlacementAvailabilities } from "src/api/material-placement-availabilities"
import { getMaterialStorages } from "src/api/material-storages"
import {
  AmMachine,
  MaterialMachine,
  MaterialPlacementAvailability,
  MaterialShelf,
  MaterialStorage,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { TableBorderedRow } from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import {
  filterPlacements,
  InventoryMaterialPlacementsFilter,
} from "src/components/organisms/materials/InventoryMaterialPlacementsFilter"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { getDisplayMaterialMachineName } from "src/domains/materials/materialMachinesRepository"
import {
  PlacementStock,
  temporaryStorageName,
} from "src/domains/materials/materialOperationStocksRepository"
import { useLoading } from "src/hooks/useLoading"
import { useMaterialInventoryPlacementsCsv } from "src/hooks/useMaterialInventoryPlacementsCsv"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { inventoryMaterialPlacementsSearchParamsState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"

export type InventoryMaterialPlacementsSearchParams = {
  name?: string
  placementType?: MaterialPlacementType
}

export interface Placement {
  placementType: MaterialPlacementType
  placementId: number
  shelfOrMachineId: number
  name: string
  isAvailable: boolean
  storage?: MaterialStorage
  shelf?: MaterialShelf
  amMachine?: AmMachine
  materialMachine?: MaterialMachine
  placementStocks?: PlacementStock[]
}

export const InventoryMaterialPlacements = () => {
  const [showSwitches, setShowSwitches] = useState(false)
  const { isEditablePlacementAvailability } = useUserRole()
  const enableAction = true

  return (
    <MainContentLayout
      title="棚・材料機械一覧"
      renderAction={() =>
        /* NOTE: 材料機械の有効・無効が正しく実装されていないのでボタンを隠す */
        !enableAction && isEditablePlacementAvailability ? (
          <Button
            variant="contained"
            onClick={() => setShowSwitches(!showSwitches)}
          >
            {showSwitches ? "変更しない" : "有効/無効変更"}
          </Button>
        ) : undefined
      }
      renderFilter={() => <InventoryMaterialPlacementsFilter />}
      renderContent={() => (
        <InventoryMaterialPlacementsMenu
          {...{ showSwitches, setShowSwitches }}
        />
      )}
    />
  )
}

interface InventoryPlacementsMenuProps {
  showSwitches: boolean
  setShowSwitches: React.Dispatch<React.SetStateAction<boolean>>
}

const InventoryMaterialPlacementsMenu: React.FC<
  InventoryPlacementsMenuProps
> = ({ showSwitches, setShowSwitches }) => {
  const { arcadeCd } = useParams()

  const { resource: materialStoragesResource, refetch: refetchStorages } =
    useResource({
      subject: "保管場所リストの取得",
      fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
      recoilKey: `getMaterialStorages:${arcadeCd}`,
    })
  const storages = useMemo(
    () => materialStoragesResource?.data.storages || [],
    [materialStoragesResource],
  )

  const { resource: materialMachinesResource, refetch: refetchFloorMapPoints } =
    useResource({
      subject: "材料機械リストの取得",
      fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
      recoilKey: `getMaterialMachine:${arcadeCd}`,
    })
  const materialMachines = useMemo(
    () => materialMachinesResource?.data.materialMachines || [],
    [materialMachinesResource],
  )

  const { resource: materialStocksResource, refetch: refetchInventoryStocks } =
    useResource({
      subject: "在庫検索結果の取得",
      fetch: arcadeCd ? () => getMaterialOperationStocks(arcadeCd) : undefined,
      recoilKey: `getMaterialOperationStocks:${arcadeCd}`,
    })
  const { shelfStocks, machineStocks } = useMemo(() => {
    const stocks = materialStocksResource?.data.stocks || []
    return {
      shelfStocks: stocks.flatMap((stock) => stock.shelfStocks),
      machineStocks: stocks.flatMap((stock) => stock.machineStocks),
    }
  }, [materialStocksResource])

  const refetch = () => {
    refetchStorages()
    refetchFloorMapPoints()
    refetchInventoryStocks()
  }

  const placements = useMemo(() => {
    const { Storage, InMachine } = MaterialPlacementType
    return [
      ...storages.flatMap<Placement>(({ storage, shelves }) =>
        (shelves || []).map((shelf) => ({
          placementType: Storage,
          placementId: shelf.id,
          shelfOrMachineId: shelf.id,
          name: `${storage.name} ${shelf.name}`,
          isAvailable: shelf.isAvailable,
          storage,
          shelf,
          placementStocks: shelfStocks.filter(
            (shelfStock) => shelfStock.shelf.id === shelf.id,
          ),
        })),
      ),
      ...materialMachines.flatMap<Placement>(
        ({ amMachine, materialMachine }) => [
          {
            placementType: InMachine,
            // TODO: fix
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            placementId: materialMachine.machineShelves![0].id,
            shelfOrMachineId: materialMachine.id,
            name: getDisplayMaterialMachineName(amMachine, materialMachine),
            // TODO: fix
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            isAvailable: materialMachine.machineShelves![0].isAvailable,
            amMachine,
            materialMachine,
            placementStocks: machineStocks.filter(
              (machineStock) =>
                machineStock.machineShelf.id ===
                // TODO: fix
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                materialMachine.machineShelves![0].id,
            ),
          },
        ],
      ),
    ]
  }, [storages, materialMachines, shelfStocks, machineStocks])

  const [placementAvailabilities, setPlacementAvailabilities] = useState<
    MaterialPlacementAvailability[]
  >([])
  const switchedPlacements = useMemo(
    () =>
      placementAvailabilities
        .map((availability) =>
          placements.find(
            (placement) =>
              placement.placementType === availability.placementType &&
              placement.placementId === availability.placementId,
          ),
        )
        .filter((placement): placement is Placement => !!placement),
    [placementAvailabilities, placements],
  )

  const searchParams = useRecoilValue(
    inventoryMaterialPlacementsSearchParamsState,
  )
  const filteredPlacements = useMemo(
    () => filterPlacements(placements, searchParams),
    [placements, searchParams],
  )

  const { downloadPlacementsCsv } = useMaterialInventoryPlacementsCsv()

  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitPromises = useLoading(setIsSubmitting).loadPromises
  const onSubmitAvailabilities = () => {
    const request = {
      placements: placementAvailabilities,
    }

    arcadeCd &&
      submitPromises([
        {
          subject: "有効/無効の更新",
          showSuccessMessage: true,
          promise: async () => {
            await patchMaterialPlacementAvailabilities(arcadeCd, request)
            setPlacementAvailabilities([])
            setShowSwitches(false)
            setShowAvailabilityModal(false)
            refetch()
          },
        },
      ])
  }

  return (
    <Stack gap={2}>
      <InventoryMaterialPlacementsTable
        {...{
          filteredPlacements,
          switchedPlacements,
          setPlacementAvailabilities,
          showSwitches,
        }}
      />

      {showSwitches ? (
        <Box
          sx={{
            background: "white",
            p: 2,
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            position: "sticky",
            bottom: 0,
            left: 0,
          }}
        >
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() => setShowSwitches(false)}
            sx={{ mr: 1 }}
          >
            キャンセル
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={() => setShowAvailabilityModal(true)}
            disabled={placementAvailabilities.length === 0}
            sx={{ ml: 1 }}
          >
            確定
          </Button>
        </Box>
      ) : (
        <Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={() => downloadPlacementsCsv(filteredPlacements)}
          >
            URLをCSVに出力する
          </Button>
        </Stack>
      )}

      <PlacementAvailabilitiesModal
        showModal={showAvailabilityModal}
        onSubmit={() => onSubmitAvailabilities()}
        onClose={() => setShowAvailabilityModal(false)}
        isSubmitting={isSubmitting}
        switchedPlacements={switchedPlacements}
      />
    </Stack>
  )
}

type InventoryPlacementsTableProps = {
  filteredPlacements: Placement[]
  switchedPlacements: Placement[]
  setPlacementAvailabilities: React.Dispatch<
    React.SetStateAction<MaterialPlacementAvailability[]>
  >
  showSwitches: boolean
}

const InventoryMaterialPlacementsTable: React.FC<
  InventoryPlacementsTableProps
> = ({
  filteredPlacements,
  switchedPlacements,
  setPlacementAvailabilities,
  showSwitches,
}) => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()
  const { Storage, InMachine } = MaterialPlacementType

  const placementHasStocks = (placement: Placement) =>
    (placement.placementStocks || []).length > 0

  const isPlacementSwitched = (placement: Placement) =>
    switchedPlacements.some(
      (switchedPlacement) =>
        switchedPlacement.placementType === placement.placementType &&
        switchedPlacement.placementId === placement.placementId,
    )

  const onClickSwitch = (placement: Placement) => {
    const { placementType, placementId, isAvailable } = placement
    const isAlreadySwitched = isPlacementSwitched(placement)
    setPlacementAvailabilities((availabilities) =>
      isAlreadySwitched
        ? availabilities.filter(
            (availability) =>
              !(
                availability.placementType === placementType &&
                availability.placementId === placementId
              ),
          )
        : [
            ...availabilities,
            {
              placementId,
              placementType,
              isAvailable: !isAvailable,
            },
          ],
    )
  }

  const isDisabled = useCallback((placement: Placement): boolean => {
    return (
      (placementHasStocks(placement) && placement.isAvailable) ||
      placement.storage?.name === temporaryStorageName
    )
  }, [])

  const [isAllSwitched, setIsAllSwitched] = useState(true)
  const onClickAllSwitch = useCallback(() => {
    const updatedAvailabilities = filteredPlacements.reduce(
      (availabilities: MaterialPlacementAvailability[], placement) => {
        const { placementType, placementId, isAvailable } = placement
        const availability = {
          placementId,
          placementType,
          isAvailable: !isAllSwitched,
        }
        if (!isDisabled(placement) && isAvailable === isAllSwitched) {
          return [...availabilities, availability]
        }
        return availabilities
      },
      [],
    )
    setPlacementAvailabilities(updatedAvailabilities)
    setIsAllSwitched((isAllSwitched) => !isAllSwitched)
  }, [
    isAllSwitched,
    filteredPlacements,
    isDisabled,
    setPlacementAvailabilities,
  ])

  const handleOnClick = useCallback(
    (placement: Placement) => {
      const { placementType, isAvailable, storage, shelf, materialMachine } =
        placement
      if (!isAvailable) return

      if (placementType === MaterialPlacementType.Storage)
        return navigate(
          `/arcades/${arcadeCd}/materials/placements/storage/${storage?.id}/${shelf?.id}`,
        )

      navigate(
        `/arcades/${arcadeCd}/materials/placements/machine/${materialMachine?.id}`,
      )
    },
    [arcadeCd, navigate],
  )

  return (
    <PaginatedTable
      noMargin
      items={filteredPlacements}
      stateKey="inventoryPlacementsTable"
      header={
        <TableHead>
          <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
            {showSwitches && (
              <ExtTableCell>
                <Switch
                  onClick={() => onClickAllSwitch()}
                  checked={isAllSwitched}
                />
              </ExtTableCell>
            )}
            <ExtTableCell>
              有効/
              <br />
              無効
            </ExtTableCell>
            <ExtTableCell>区分</ExtTableCell>
            <ExtTableCell>名前</ExtTableCell>
          </TableRow>
        </TableHead>
      }
      renderRow={(placement) => {
        const { placementType, shelfOrMachineId, name, isAvailable } = placement
        const isAvailableAfterSwitching = !isAvailable
        const isSwitched = isPlacementSwitched(placement)

        return (
          <TableBorderedRow
            key={`${placementType}-${shelfOrMachineId}-${name}`}
            data-testid={`${placementType}-${shelfOrMachineId}-${name}`}
            sx={{ ...(!isAvailable && { background: theme.palette.gray[20] }) }}
          >
            {showSwitches && (
              <ExtTableCell sx={{ p: 0 }}>
                <Switch
                  onClick={() => onClickSwitch(placement)}
                  checked={isSwitched ? isAvailableAfterSwitching : isAvailable}
                  disabled={isDisabled(placement)}
                />
              </ExtTableCell>
            )}
            <ExtTableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
              {isSwitched ? (
                isAvailableAfterSwitching ? (
                  <Typography
                    sx={{
                      background: theme.palette.primary.main,
                      color: "white",
                      p: 1,
                    }}
                    variant="subtitle1"
                  >
                    有効
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      background: theme.palette.text.disabled,
                      color: "white",
                      p: 1,
                    }}
                    variant="subtitle1"
                  >
                    無効
                  </Typography>
                )
              ) : isAvailable ? (
                <Typography color="primary.main" p={1} variant="subtitle1">
                  有効
                </Typography>
              ) : (
                <Typography color="text.disabled" p={1} variant="subtitle1">
                  無効
                </Typography>
              )}
            </ExtTableCell>
            <ExtTableCell sx={{ p: 1 }}>
              {placementType === Storage && "［外］"}
              {placementType === InMachine && "［内］"}
            </ExtTableCell>
            <ExtTableCell
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  background: (theme) => theme.palette.neutral[200],
                },
                p: 1,
              }}
              onClick={() => handleOnClick(placement)}
            >
              {name}
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}

type PlacementAvailabilitiesModalProps = {
  showModal: boolean
  onSubmit: () => void
  onClose: () => void
  isSubmitting: boolean
  switchedPlacements: Placement[]
}

const PlacementAvailabilitiesModal: React.FC<
  PlacementAvailabilitiesModalProps
> = ({
  showModal,
  onSubmit,
  onClose,
  isSubmitting,
  switchedPlacements,
}: PlacementAvailabilitiesModalProps) => {
  const { Storage, InMachine } = MaterialPlacementType

  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <DialogContent>
          <Typography sx={{ mb: 3 }} variant="h1">
            本当に変更しますか？
          </Typography>

          <PaginatedTable
            noMargin
            items={switchedPlacements}
            header={
              <TableHead>
                <TableRow sx={{ th: { p: 1, whiteSpace: "nowrap" } }}>
                  <ExtTableCell>変更後</ExtTableCell>
                  <ExtTableCell>区分</ExtTableCell>
                  <ExtTableCell>名前</ExtTableCell>
                </TableRow>
              </TableHead>
            }
            renderRow={(placement) => {
              const { placementType, shelfOrMachineId, name, isAvailable } =
                placement
              const isAvailableAfterSwitching = !isAvailable

              return (
                <TableBorderedRow
                  key={`${placementType}-${shelfOrMachineId}-${name}`}
                >
                  <ExtTableCell sx={{ whiteSpace: "nowrap", p: 0 }}>
                    {isAvailableAfterSwitching ? (
                      <Typography
                        sx={{
                          background: theme.palette.primary.main,
                          color: "white",
                          p: 1,
                        }}
                        variant="subtitle1"
                      >
                        有効
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          background: theme.palette.text.disabled,
                          color: "white",
                          p: 1,
                        }}
                        variant="subtitle1"
                      >
                        無効
                      </Typography>
                    )}
                  </ExtTableCell>
                  <ExtTableCell sx={{ p: 1 }}>
                    {placementType === Storage && "［外］"}
                    {placementType === InMachine && "［内］"}
                  </ExtTableCell>
                  <ExtTableCell
                    sx={{
                      p: 1,
                    }}
                  >
                    {name}
                  </ExtTableCell>
                </TableBorderedRow>
              )
            }}
          />
        </DialogContent>
        <CustomDialogActions>
          <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
          <LoadingButton
            variant="contained"
            fullWidth
            loading={isSubmitting}
            onClick={onSubmit}
          >
            変更する
          </LoadingButton>
        </CustomDialogActions>
      </CustomDialog>
    </>
  )
}
