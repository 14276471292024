import { useMemo } from "react"

import { useNavigate, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"

import {
  MaterialMachinesElement,
  MaterialMachineWarningEnum,
} from "src/api/models"
import {
  InventoryMaterialExecuteInMachineAmMachinesFilter,
  inventoryMaterialExecuteInMachineAmMachinesSearchParamsState,
} from "src/components/organisms/materials/InventoryMaterialExecuteInMachineAmMachinesFilter"
import {
  InventoryMaterialExecutePlacementListItem,
  InventoryMaterialExecuteList,
  amMachineInventoryMaterialExecuteListColumns,
} from "src/components/organisms/materials/InventoryMaterialExecuteList"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { executionPeriodTentativeEndAt } from "src/domains/inventoryExecutionPeriodRepository"
import { MaterialPlacementType } from "src/domains/materials/materialInventoryPlacementStatusRepository"
import { useInventoryExecuteMachines } from "src/hooks/useInventoryExecuteMachines"
import { compareByPhoneticOrder } from "src/utils"

export const InventoryMaterialExecuteInMachineAmMachines: React.FC = () => {
  const searchParams = useRecoilValue(
    inventoryMaterialExecuteInMachineAmMachinesSearchParamsState,
  )

  const { placementListItems, materialMachineGroupNames, materialMachines } =
    useInventoryExecuteMachines(
      searchParams.amMachineName
        ? { amMachineName: searchParams.amMachineName }
        : undefined,
    )

  return (
    <MainContentLayout
      title="在庫カウント(材料機械[内])"
      renderFilter={() => (
        <InventoryMaterialExecuteInMachineAmMachinesFilter
          gorupNames={materialMachineGroupNames}
        />
      )}
      renderContent={() => (
        <InventoryMaterialExecuteInMachineAmMachineTable
          placementListItems={placementListItems}
          materialMachines={materialMachines}
        />
      )}
    />
  )
}

type AmMachineListMap = Map<
  string,
  InventoryMaterialExecutePlacementListItem<"in_machine">[]
>

const InventoryMaterialExecuteInMachineAmMachineTable: React.FC<{
  placementListItems: InventoryMaterialExecutePlacementListItem<"in_machine">[]
  materialMachines: MaterialMachinesElement[]
}> = ({ placementListItems, materialMachines }) => {
  const navigate = useNavigate()
  const { arcadeCd } = useParams()

  const searchParams = useRecoilValue(
    inventoryMaterialExecuteInMachineAmMachinesSearchParamsState,
  )

  const handleOnClick = ({
    amMachineNumber,
  }: InventoryMaterialExecutePlacementListItem<"in_machine">) => {
    navigate(
      `/arcades/${arcadeCd}/inventory/materials/execute/machine/amMachines/${amMachineNumber}`,
    )
  }

  const warningMaterialMachines = materialMachines.filter(
    (materialMachine) =>
      materialMachine.materialMachine.warning !==
      MaterialMachineWarningEnum.None,
  )

  const amMachineListItems: InventoryMaterialExecutePlacementListItem<"in_machine">[] =
    useMemo(() => {
      // 材料機械単位のリストを、AM機器単位のリストに変更する
      // AM機器番号をkeyにした材料機械リストのMapを経由することで、AM機器単位に変換している
      const amMachineListMap: AmMachineListMap = new Map()
      placementListItems.map((item) => {
        const list = amMachineListMap.get(item.amMachineNumber || "") || []
        list.push(item)
        amMachineListMap.set(item.amMachineNumber || "", list)
      })
      return Array.from(amMachineListMap.values()).map((list) => {
        const { amMachineNumber, materialMachineId, name, parentName } = list[0]
        return {
          amMachineNumber: amMachineNumber,
          materialMachineId: materialMachineId,
          name: name,
          parentName: parentName,
          isAvailable: !list.every((i) => !i.isAvailable),
          executionStatus: list.some(
            (i) => i.executionStatus === "not_executed",
          )
            ? "not_executed"
            : list.some((i) => i.executionStatus === "executed")
              ? "executed"
              : "empty",
          executedAt: list.reduce((oldest, current) => {
            if (!current.executedAt) return ""
            if (oldest >= current.executedAt) {
              return current.executedAt
            } else {
              return oldest
            }
          }, executionPeriodTentativeEndAt),
          warning: list.some((i) => i.warning === "amMachineArcadeChanged")
            ? "amMachineArcadeChanged"
            : list.some((i) => i.warning === "amMachineDisabled")
              ? "amMachineDisabled"
              : "none",
        }
      })
    }, [placementListItems])

  const filteredListItems = useMemo(() => {
    let listItems = amMachineListItems

    if (searchParams.groupName) {
      listItems = listItems.filter(
        (item) => item.groupName === searchParams.groupName,
      )
    }

    if (searchParams.sortBy) {
      if (searchParams.sortBy === "amMachineNameAsc") {
        listItems = [...listItems].sort((a, b) =>
          compareByPhoneticOrder(a.name, b.name),
        )
      } else if (searchParams.sortBy === "amMachineNameDesc") {
        listItems = [...listItems].sort((a, b) =>
          compareByPhoneticOrder(b.name, a.name),
        )
      }
    }

    return listItems
  }, [amMachineListItems, searchParams])

  return (
    <InventoryMaterialExecuteList
      placement={MaterialPlacementType.InMachine}
      listItems={filteredListItems}
      warningMaterialMachines={warningMaterialMachines}
      columns={amMachineInventoryMaterialExecuteListColumns}
      handleOnClick={handleOnClick}
    />
  )
}
