import { forwardRef, useMemo } from "react"

import { Search } from "@mui/icons-material"
import { Autocomplete, InputAdornment, SvgIcon, TextField } from "@mui/material"

import { WordBreakAllList } from "src/components/atoms/WordBreakAllList"

type Value = string | number | undefined
export type SearchAutoCompleteItem = {
  label: string
  value: Value
}

export type SearchAutoCompleteProps = {
  onChange: (value: Value) => void
  value: Value
  items: SearchAutoCompleteItem[]
  inputLabel?: string
  error?: boolean
  disabled?: boolean
}

export const SearchAutoComplete = forwardRef<
  HTMLElement,
  SearchAutoCompleteProps
>(({ onChange, value, items, inputLabel, error, disabled = false }, ref) => {
  const autocompleteValue = useMemo(() => {
    const label = items.find((item) => item.value === value)?.label ?? ""
    if (!label || !value) return null
    return {
      label,
      value,
    }
  }, [items, value])
  return (
    <Autocomplete
      ref={ref}
      value={autocompleteValue}
      options={items.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      renderOption={(props, option) => (
        <WordBreakAllList {...props} key={option.value}>
          {option.label}
        </WordBreakAllList>
      )}
      onChange={(_, option) => onChange(option?.value)}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  sx={(theme) => ({
                    color: theme.palette.gray[40],
                  })}
                  fontSize="small"
                >
                  <Search />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
      )}
      disabled={disabled}
    />
  )
})

SearchAutoComplete.displayName = "SearchAutoComplete"
