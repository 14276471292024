import React, { useState, Suspense, useMemo, useEffect, ReactNode } from "react"

import {
  Box,
  Grid,
  Card,
  CircularProgress,
  Button,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import { getMaterialArrivals } from "src/api/material-arrivals"
import { MaterialArrivalElement } from "src/api/models"
import finishedIcon from "src/assets/icon_finished.png"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardItemNameBox,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { DefaultDateRangePicker } from "src/components/organisms/DefaultDateRangePicker"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getArrivalCount } from "src/domains/materials/arrivalRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  InventoryMaterialAcceptMultipleConfirm,
  InventoryMaterialAcceptMultipleForm,
} from "src/pages/materials/register/InventoryMaterialAcceptMultipleForm"
import {
  MaterialAcceptResult,
  InventoryMaterialAcceptSingleConfirm,
  InventoryMaterialAcceptSingleForm,
} from "src/pages/materials/register/InventoryMaterialAcceptSingleForm"
import {
  defaultDateRangePickerDateLabelStateSelector,
  snackbarSuccessMessageState,
} from "src/recoil"
import { showOnlyNotAcceptedMaterialsState } from "src/recoil/inventoryMaterials"
import { theme } from "src/theme"
import { formatApiDate, getJpDateLabel } from "src/utils"

type PageState = "list" | "form" | "confirm"
type Page = {
  subTitle?: string
  content: ReactNode
  onClickBackButton?: () => void
  backButtonLabel?: string
}

export const InventoryMaterialAccept = () => {
  const [pageState, setPageState] = useState<PageState>("list")
  const [arrivals, setArrivals] = useState<MaterialArrivalElement[]>([])
  const [results, setResult] = useState<MaterialAcceptResult[]>([])

  const onClose = () => {
    setArrivals([])
    setResult([])
    setPageState("list")
  }
  const onAccept = (selectedArrivals: MaterialArrivalElement[]) => {
    setArrivals(selectedArrivals)
    setPageState("form")
  }
  const onAccepted = (results: MaterialAcceptResult[]) => {
    setResult(results)
    setPageState("confirm")
  }

  let page = {} as Page
  if (pageState === "list") {
    page = {
      subTitle: "着荷予定リスト",
      content: <InventoryMaterialAcceptArrivalList onAccept={onAccept} />,
    }
  }
  if (pageState === "form") {
    if (arrivals.length === 1) {
      page = {
        subTitle: "選択した材料を検収する",
        content: (
          <InventoryMaterialAcceptSingleForm
            arrival={arrivals[0]}
            material={arrivals[0].material}
            onAccepted={onAccepted}
          />
        ),
        onClickBackButton: () => onClose(),
        backButtonLabel: "確定せず戻る",
      }
    } else {
      page = {
        subTitle: "まとめて検収",
        content: (
          <InventoryMaterialAcceptMultipleForm
            arrivals={arrivals}
            onAccepted={onAccepted}
          />
        ),
        onClickBackButton: () => onClose(),
        backButtonLabel: "保存せず戻る",
      }
    }
  }
  if (pageState === "confirm") {
    if (arrivals.length === 1) {
      page = {
        content: <InventoryMaterialAcceptSingleConfirm result={results[0]} />,
        onClickBackButton: () => onClose(),
      }
    } else {
      page = {
        content: <InventoryMaterialAcceptMultipleConfirm results={results} />,
        onClickBackButton: () => onClose(),
      }
    }
  }

  return (
    <MainContentLayout
      title="入庫検収"
      subtitle={page.subTitle}
      renderContent={() => page.content}
      onClickBackButton={page.onClickBackButton}
      backButtonLabel={page.backButtonLabel}
    />
  )
}

interface InventoryMaterialAcceptArrivalListProps {
  onAccept: (selectedArrivals: MaterialArrivalElement[]) => void
}

const InventoryMaterialAcceptArrivalList = (
  props: InventoryMaterialAcceptArrivalListProps,
) => {
  const [showOnlyNotAcceptedMaterials, setShowOnlyNotAcceptedMaterials] =
    useRecoilState(showOnlyNotAcceptedMaterialsState)
  const { arcadeCd } = useParams()
  const { isAdmin } = useUserRole()

  return (
    <>
      <Box mb={1}>
        <Card>
          <Box p={2}>
            <DefaultDateRangePicker />
          </Box>
        </Card>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              mb: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              checked={showOnlyNotAcceptedMaterials}
              onChange={() =>
                setShowOnlyNotAcceptedMaterials(!showOnlyNotAcceptedMaterials)
              }
              control={<Checkbox />}
              disableTypography
              label="未入荷材料のみを表示する"
            />
          </Box>
        </Grid>

        <Suspense fallback={<CircularProgress sx={{ margin: "auto" }} />}>
          <InventoryMaterialAcceptListMenu {...props} />
        </Suspense>

        {isAdmin && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{ background: "white" }}
              fullWidth
              component={RouterLink}
              to={`/arcades/${arcadeCd}/materials/register/accept/search`}
            >
              検索して登録する
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}

const InventoryMaterialAcceptListMenu = ({
  onAccept,
}: InventoryMaterialAcceptArrivalListProps) => {
  const { arcadeCd } = useParams()

  const showOnlyNotAcceptedMaterials = useRecoilValue(
    showOnlyNotAcceptedMaterialsState,
  )
  const dateRangePickerDateLabel = useRecoilValue(
    defaultDateRangePickerDateLabelStateSelector,
  )
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)

  const { resource } = useResource({
    subject: "着荷予定リストの取得",
    fetch: arcadeCd
      ? () =>
          getMaterialArrivals(arcadeCd, {
            from: dateRangePickerDateLabel.start,
            to: dateRangePickerDateLabel.end,
            accepted: showOnlyNotAcceptedMaterials ? false : undefined,
          })
      : undefined,
    recoilKey: `getMaterialArrivals:${arcadeCd}:${dateRangePickerDateLabel.start}:${dateRangePickerDateLabel.end}:${showOnlyNotAcceptedMaterials}`,
  })

  const arrivals = useMemo(() => {
    const arrivals = resource?.data.arrivals || []
    if (arrivals?.length === 0) {
      setSuccessMessage("当日の着荷予定が存在しませんでした")
    }
    return arrivals
  }, [resource, setSuccessMessage])
  const [selectedArrivals, setSelectedArrivals] = useState<
    MaterialArrivalElement[]
  >([])

  useEffect(() => {
    // NOTE: 日付変更時には選択中の着荷予定をリセット
    setSelectedArrivals([])
  }, [dateRangePickerDateLabel])

  return (
    <>
      {arrivals && (
        <Grid item xs={12}>
          <ArrivalTable
            arrivals={arrivals}
            selectedArrivals={selectedArrivals}
            setSelectedArrivals={setSelectedArrivals}
          />
        </Grid>
      )}

      <Grid item xs={12} sx={{ mt: 1 }}>
        <Button
          variant="contained"
          fullWidth
          disabled={selectedArrivals.length === 0}
          onClick={() => onAccept(selectedArrivals)}
        >
          {selectedArrivals.length > 1
            ? "選択した材料をまとめて検収する"
            : selectedArrivals.length === 1 &&
                selectedArrivals[0].arrival.accepted
              ? "検収内容を変更する"
              : "選択した材料を検収する"}
        </Button>
      </Grid>
    </>
  )
}

interface ArrivalTableProps {
  arrivals: MaterialArrivalElement[]
  selectedArrivals: MaterialArrivalElement[]
  setSelectedArrivals: React.Dispatch<
    React.SetStateAction<MaterialArrivalElement[]>
  >
}

const ArrivalTable: React.FC<ArrivalTableProps> = ({
  arrivals,
  selectedArrivals,
  setSelectedArrivals,
}: ArrivalTableProps) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const dateRangePickerDateLabel = useRecoilValue(
    defaultDateRangePickerDateLabelStateSelector,
  )

  const columnNames = ["選択", "材料名", "着荷数", "着荷予定日", "検収済"]

  const onClickCheckbox = (arrival: MaterialArrivalElement) =>
    selectedArrivals.some(
      (selectedArrival) => selectedArrival.arrival.id === arrival.arrival.id,
    )
      ? setSelectedArrivals((state) => {
          const newState = [...state]
          newState.splice(
            state.findIndex((a) => a.arrival.id === arrival.arrival.id),
            1,
          )
          return newState
        })
      : setSelectedArrivals((state) => [...state, arrival])

  const onClickArrival = (arrival: MaterialArrivalElement) => {
    const arrivalDate = formatApiDate(arrival.arrival.willArriveAt || "")
    navigate(
      `/arcades/${arcadeCd}/materials/register/accept/details/${arrivalDate}/${encodeURIComponent(
        arrival.material.materialCd,
      )}`,
    )
  }

  return (
    <PaginatedTable
      noMargin
      items={arrivals}
      stateKey={`arrivalTable-${dateRangePickerDateLabel}`}
      header={
        <TableHead>
          <TableRow>
            {columnNames.map((columnName) => (
              <ExtTableCell
                sx={{ px: 1, whiteSpace: "nowrap" }}
                key={columnName}
              >
                {columnName}
              </ExtTableCell>
            ))}
          </TableRow>
        </TableHead>
      }
      renderRow={(a) => {
        return (
          <TableBorderedRow
            key={a.arrival.id}
            sx={{
              td: { p: 1 },
            }}
          >
            <ExtTableCell>
              <Checkbox
                sx={{ p: 0 }}
                onClick={() => onClickCheckbox(a)}
                checked={selectedArrivals.some(
                  (selectedArrival) =>
                    selectedArrival.arrival.id === a.arrival.id,
                )}
              />
            </ExtTableCell>
            <ExtTableCell
              sx={{
                color: theme.palette.primary.main,
                "&:hover": {
                  cursor: "pointer",
                  background: theme.palette.neutral[200],
                },
              }}
              onClick={() => onClickArrival(a)}
            >
              <CardItemNameBox>{a.material.materialName}</CardItemNameBox>
            </ExtTableCell>
            <ExtTableCell>{getArrivalCount(a)}</ExtTableCell>
            <ExtTableCell>
              {getJpDateLabel(a.arrival.willArriveAt)}
            </ExtTableCell>
            <ExtTableCell>
              {a.arrival.accepted && (
                <img
                  src={finishedIcon}
                  alt="済"
                  style={{ width: 32, height: 32 }}
                />
              )}
            </ExtTableCell>
          </TableBorderedRow>
        )
      }}
    />
  )
}
