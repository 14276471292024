import { useState, useEffect, Fragment, useMemo } from "react"

import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  Card,
  Table,
  TableBody,
  Button,
  RadioGroup,
  Radio,
  Chip,
  FormControlLabel,
  Checkbox,
  TextField,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"

import {
  PrizeBoothStock,
  PrizeOperationStock,
  PrizeOnBoothStock,
  PatchPrizeOperationStocksRequest,
  Prize,
  PrizeShelfStock,
} from "src/api/models"
import {
  getPrizeOperationStocks,
  patchPrizeOperationStocks,
} from "src/api/prize-operation-stocks"
import { InventoryPrizeMoveToBooth } from "src/components//templates/InventoryPrizeMoveToBooth"
import { InventoryPrizeMoveToShelf } from "src/components//templates/InventoryPrizeMoveToShelf"
import { BackButton } from "src/components/atoms/BackButton"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { CustomDialogActions } from "src/components/molecules/CustomDialogActions"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { DeleteModal } from "src/components/organisms/DeleteModal"
import { PlacementType } from "src/domains/prizes/placementStatusRepository"
import { useDeleteInventoryStocks } from "src/hooks/useDeleteInventoryStocks"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { hideEmptyPrizeState } from "src/recoil/inventory"

export interface PlacementIdMoveFrom {
  shelfId?: number
  boothShelfId?: number
  onBoothShelfId?: number
}

export interface StockMoveFrom {
  shelfStock?: PrizeShelfStock
  boothStock?: PrizeBoothStock
  onBoothStock?: PrizeOnBoothStock
}

export const getPlacementMoveFrom = (stockMoveFrom: StockMoveFrom) => {
  const { shelfStock, boothStock, onBoothStock } = stockMoveFrom
  const { Storage, InBooth, OnBooth } = PlacementType
  if (shelfStock) {
    const { storageName, shelfName, stock, id } = shelfStock
    return {
      name: `${storageName} ${shelfName}`,
      stock,
      placementType: Storage,
      id,
    }
  }
  if (boothStock) {
    const { boothShelfName, stock, id } = boothStock
    return {
      name: boothShelfName,
      stock,
      placementType: InBooth,
      id,
    }
  }
  if (onBoothStock) {
    const { onBoothShelfName, stock, id } = onBoothStock
    return {
      name: (
        <>
          {onBoothShelfName}
          <Chip label="プライズ機上" size="small" />
        </>
      ),
      stock,
      placementType: OnBooth,
      id,
    }
  }
  return {
    name: "-",
    stock: 0,
  }
}

export const updateStockMoveFrom = (
  stockMoveFrom: StockMoveFrom,
  stocks: PrizeOperationStock[],
): StockMoveFrom => ({
  shelfStock: stocks.flatMap((stock) =>
    stock.shelfStocks.filter(
      (stock) => stock.shelfId === stockMoveFrom.shelfStock?.shelfId,
    ),
  )[0],
  boothStock: stocks.flatMap((stock) =>
    stock.boothStocks.filter(
      (stock) => stock.boothShelfId === stockMoveFrom.boothStock?.boothShelfId,
    ),
  )[0],
  onBoothStock: stocks.flatMap((stock) =>
    stock.onBoothStocks.filter(
      (stock) =>
        stock.onBoothShelfId === stockMoveFrom.onBoothStock?.onBoothShelfId,
    ),
  )[0],
})

interface InventoryPrizeStockDetailsProps {
  prizeCd: Prize["prizeCd"]
  isTemporaryStorages?: boolean
  isPlacementPrize?: boolean
  defaultPlacementIdMoveFrom?: PlacementIdMoveFrom
}

export const InventoryPrizeStockDetails: React.FC<
  InventoryPrizeStockDetailsProps
> = (props) => {
  return <InventoryPrizeStockDetailsMenu {...props} />
}

const InventoryPrizeStockDetailsMenu: React.FC<
  InventoryPrizeStockDetailsProps
> = ({
  prizeCd,
  isTemporaryStorages = false,
  isPlacementPrize = false,
  defaultPlacementIdMoveFrom = {},
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const [placementIdMoveFrom, setPlacementIdMoveFrom] =
    useState<PlacementIdMoveFrom>(defaultPlacementIdMoveFrom)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [hideEmptyPrize, setHideEmptyPrize] =
    useRecoilState(hideEmptyPrizeState)
  const { resource, refetch } = useResource({
    subject: "在庫情報の取得",
    fetch: arcadeCd
      ? () => getPrizeOperationStocks(arcadeCd, { prizeCd })
      : undefined,
    // FIXME: refetch 時のローディング表示を避けるため、他コンポーネントと key がかぶらないようコンポーネント名を入れている
    recoilKey: `InventoryStockDetails:getInventoryStocks:${arcadeCd}:${prizeCd}`,
  })
  const stocks = resource?.data.stocks
  useEffect(() => {
    if (stocks?.length === 0) return navigate(-1)
  }, [stocks, navigate])
  const stock = stocks?.find((s) => s.prize.prizeCd === prizeCd)

  const onFinish = () => refetch()

  const getStockMoveFrom = () => {
    const { shelfId, boothShelfId, onBoothShelfId } = placementIdMoveFrom || {}
    const { shelfStocks, boothStocks, onBoothStocks } = stock || {}

    if (shelfId) {
      const shelfStock = shelfStocks?.find((s) => s.shelfId === shelfId)
      if (shelfStock) {
        return { shelfStock }
      }
    }
    if (boothShelfId) {
      const boothStock = boothStocks?.find(
        (s) => s.boothShelfId === boothShelfId,
      )
      if (boothStock) {
        return { boothStock }
      }
    }
    if (onBoothShelfId) {
      const onBoothStock = onBoothStocks?.find(
        (s) => s.onBoothShelfId === onBoothShelfId,
      )
      if (onBoothStock) {
        return { onBoothStock }
      }
    }
    return
  }
  const stockMoveFrom: StockMoveFrom | undefined = getStockMoveFrom()

  const [placementTypeMoveTo, setPlacementTypeMoveTo] =
    useState<PlacementType>()
  const { Storage, InBooth, OnBooth } = PlacementType
  const [showChangeCount, setShowChangeCount] = useState(false)
  const { prizeName, unitPriceJpy, isKidsMarket } = stock?.prize || {}

  // NOTE: 荷捌きの場合は棚がひとつだけなので、先頭の棚を自動選択
  useEffect(() => {
    if (isTemporaryStorages && stock?.shelfStocks) {
      setPlacementIdMoveFrom({ shelfId: stock.shelfStocks[0]?.shelfId })
    }
  }, [isTemporaryStorages, stock])

  const onFinishMoving = () => {
    onFinish()
    if (isTemporaryStorages && stock?.shelfStocks) {
      setPlacementIdMoveFrom({ shelfId: stock.shelfStocks[0].shelfId })
    } else {
      setPlacementIdMoveFrom(placementIdMoveFrom)
    }
    setPlacementTypeMoveTo(undefined)
  }

  const listItems = [
    {
      key: "景品名",
      value: prizeName,
    },
    {
      key: "景品CD",
      value: prizeCd,
    },
    {
      key: "景品単価",
      value: unitPriceJpy ? unitPriceJpy + "円" : "",
    },
  ].filter((item) => !!item)

  const { onDeleteInventoryStocks } = useDeleteInventoryStocks()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onDeleteSubmit = async () => {
    if (!stockMoveFrom || !arcadeCd) return
    const stock = getPlacementMoveFrom(stockMoveFrom)
    if (stock.id) {
      setIsSubmitting(true)
      await onDeleteInventoryStocks({
        arcadeCd,
        placementStockId: stock.id,
        placement: stock.placementType,
      })
      onFinish()
      setIsSubmitting(false)
    }
    setShowDeleteModal(false)
  }

  return !placementTypeMoveTo ? (
    <Box>
      <ChangeStockCountModal
        showModal={showChangeCount}
        stock={getStockMoveFrom()}
        onClose={() => setShowChangeCount(false)}
        onFinish={onFinish}
      />
      <DeleteModal
        showModal={showDeleteModal}
        onSubmit={onDeleteSubmit}
        onClose={() => setShowDeleteModal(false)}
        isSubmitting={isSubmitting}
      />
      <Box
        sx={{
          mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          checked={hideEmptyPrize}
          onChange={() => setHideEmptyPrize(!hideEmptyPrize)}
          control={<Checkbox />}
          disableTypography
          label="0個のものは表示しない"
        />
      </Box>
      <Card sx={{ mb: 2 }}>
        <Table>
          <TableBody>
            <TableBorderedRow>
              <ExtTableCell colSpan={2}>
                <Box sx={{ maxWidth: "90%", margin: "auto" }}>
                  <PrizeImageBox
                    prizeCd={prizeCd}
                    alt={prizeName}
                    noImageSize="large"
                  />
                </Box>
              </ExtTableCell>
            </TableBorderedRow>

            {listItems.map(
              (item) =>
                item && (
                  <TableBorderedRow key={item.key}>
                    <CardKeyCell>
                      <Typography variant="body2">{item.key}</Typography>
                    </CardKeyCell>
                    <CardValueCell>
                      <Typography variant="body2"> {item.value}</Typography>
                    </CardValueCell>
                  </TableBorderedRow>
                ),
            )}

            <TableBorderedRow>
              <CardKeyCell>
                <Typography variant="body2">在庫</Typography>
              </CardKeyCell>
              <CardValueCell>
                <Typography variant="body2" component="div">
                  <RadioGroup>
                    <Grid container mb={-1}>
                      {stock?.shelfStocks
                        .filter((s) => (hideEmptyPrize ? s.stock > 0 : true))
                        .map((s) => (
                          <Fragment key={s.shelfId}>
                            <Grid item xs={9} data-testid={s.shelfName}>
                              <FormControlLabel
                                onChange={() =>
                                  setPlacementIdMoveFrom({
                                    shelfId: s.shelfId,
                                  })
                                }
                                checked={
                                  !!stockMoveFrom?.shelfStock &&
                                  s.shelfId === stockMoveFrom.shelfStock.shelfId
                                }
                                disableTypography
                                control={<Radio sx={{ p: 0, pr: 1 }} />}
                                label={`${s.storageName} ${s.shelfName}`}
                              />
                            </Grid>

                            <Grid item xs={3} data-testid={s.shelfName}>
                              {s.stock} 個
                            </Grid>
                          </Fragment>
                        ))}
                      {stock?.boothStocks
                        .filter((s) => (hideEmptyPrize ? s.stock > 0 : true))
                        .map((s) => (
                          <Fragment key={`${s.boothShelfId}`}>
                            <Grid item xs={9} data-testid={s.boothShelfName}>
                              <FormControlLabel
                                onChange={() =>
                                  setPlacementIdMoveFrom({
                                    boothShelfId: s.boothShelfId,
                                  })
                                }
                                checked={
                                  !!stockMoveFrom?.boothStock &&
                                  s.boothShelfName ===
                                    stockMoveFrom.boothStock.boothShelfName
                                }
                                disableTypography
                                control={<Radio sx={{ p: 0, pr: 1 }} />}
                                label={s.boothShelfName}
                              />
                            </Grid>

                            <Grid item xs={3} data-testid={s.boothShelfName}>
                              {s.stock} 個
                            </Grid>
                          </Fragment>
                        ))}
                      {stock?.onBoothStocks
                        .filter((s) => (hideEmptyPrize ? s.stock > 0 : true))
                        .map((s) => (
                          <Fragment key={s.onBoothShelfName}>
                            <Grid item xs={9} data-testid={s.onBoothShelfName}>
                              <FormControlLabel
                                onChange={() =>
                                  setPlacementIdMoveFrom({
                                    onBoothShelfId: s.onBoothShelfId,
                                  })
                                }
                                checked={
                                  !!stockMoveFrom?.onBoothStock &&
                                  s.onBoothShelfName ===
                                    stockMoveFrom.onBoothStock.onBoothShelfName
                                }
                                disableTypography
                                control={<Radio sx={{ p: 0, pr: 1 }} />}
                                label={
                                  <Box>
                                    {s.onBoothShelfName}
                                    <Chip label="プライズ機上" size="small" />
                                  </Box>
                                }
                              />
                            </Grid>

                            <Grid item xs={3} data-testid={s.onBoothShelfName}>
                              {s.stock} 個
                            </Grid>
                          </Fragment>
                        ))}
                    </Grid>
                  </RadioGroup>
                </Typography>
              </CardValueCell>
            </TableBorderedRow>
          </TableBody>
        </Table>
      </Card>

      <Button
        variant="outlined"
        sx={{ background: "white", mb: 2 }}
        fullWidth
        onClick={() => setShowChangeCount(true)}
        disabled={!stockMoveFrom}
      >
        個数を変更する
      </Button>

      <Button
        variant="outlined"
        sx={{ background: "white", mb: 2 }}
        fullWidth
        onClick={() => setPlacementTypeMoveTo(InBooth)}
        disabled={!stockMoveFrom || isKidsMarket}
      >
        ブースに投入する
      </Button>

      <Button
        variant="outlined"
        sx={{ background: "white", mb: 2 }}
        fullWidth
        onClick={() => setPlacementTypeMoveTo(Storage)}
        disabled={!stockMoveFrom}
      >
        棚に移動する
      </Button>

      <Button
        variant="outlined"
        sx={{ background: "white", mb: 2 }}
        fullWidth
        onClick={() => setPlacementTypeMoveTo(OnBooth)}
        disabled={!stockMoveFrom || isKidsMarket}
      >
        プライズ機上に移動する
      </Button>

      <Button
        variant="outlined"
        sx={{ mb: 3 }}
        color="error"
        fullWidth
        onClick={() => setShowDeleteModal(true)}
        disabled={
          !stockMoveFrom || getPlacementMoveFrom(stockMoveFrom).stock !== 0
        }
      >
        削除する
      </Button>

      <BackButton fullWidth>
        {isTemporaryStorages || isPlacementPrize ? "一覧" : "検索"}画面に戻る
      </BackButton>
    </Box>
  ) : (
    <Box>
      {stock && stockMoveFrom && (
        <>
          {(placementTypeMoveTo === InBooth ||
            placementTypeMoveTo === OnBooth) && (
            <InventoryPrizeMoveToBooth
              prize={stock.prize}
              stockMoveFrom={stockMoveFrom}
              isInBooth={placementTypeMoveTo === InBooth}
              onFinish={() => onFinishMoving()}
            />
          )}
          {placementTypeMoveTo === Storage && (
            <InventoryPrizeMoveToShelf
              prize={stock.prize}
              stockMoveFrom={stockMoveFrom}
              onFinish={() => onFinishMoving()}
            />
          )}
        </>
      )}

      <BackButton onClick={() => setPlacementTypeMoveTo(undefined)}>
        景品詳細に戻る
      </BackButton>
    </Box>
  )
}

interface ChangeStockCountFormInput {
  count: number
}

interface ChangeStockCountModalProps {
  showModal: boolean
  stock?: StockMoveFrom
  onClose: () => void
  onFinish: () => void
}

export const ChangeStockCountModal: React.FC<ChangeStockCountModalProps> = ({
  showModal,
  stock,
  onClose,
  onFinish = () => undefined,
}) => {
  const { arcadeCd } = useParams()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValid },
  } = useForm<ChangeStockCountFormInput>()

  const { targetStock, targetPlacement, targetCount } = useMemo(() => {
    const { shelfStock, boothStock, onBoothStock } = stock ?? {}
    const { Storage, InBooth, OnBooth } = PlacementType

    if (shelfStock) {
      return {
        targetPlacement: Storage,
        targetStock: shelfStock,
        targetCount: shelfStock.stock,
      }
    }
    if (boothStock) {
      return {
        targetPlacement: InBooth,
        targetStock: boothStock,
        targetCount: boothStock.stock,
      }
    }
    if (onBoothStock) {
      return {
        targetPlacement: OnBooth,
        targetStock: onBoothStock,
        targetCount: onBoothStock.stock,
      }
    }
    return {}
  }, [stock])

  useEffect(() => reset({ count: targetCount }), [reset, targetCount])

  const { submitPromises } = useSubmitting()
  const onSubmit = async (data: { count: number }) => {
    if (!arcadeCd || !targetStock || !targetPlacement || isNaN(data.count))
      return

    const request: PatchPrizeOperationStocksRequest = {
      placement: targetPlacement,
      placementStockId: targetStock.id,
      stock: data.count,
    }

    await submitPromises([
      {
        subject: "在庫の更新",
        showSuccessMessage: true,
        promise: async () => {
          await patchPrizeOperationStocks(arcadeCd, request)
          onClose()
          onFinish()
        },
      },
    ])
  }

  return (
    <>
      <CustomDialog fullWidth maxWidth="sm" open={showModal} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle sx={{ mt: 3, textAlign: "center" }}>
            在庫個数の変更
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              sx={{ my: 4 }}
              label="個数"
              {...register("count", {
                required: true,
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: "0以上の値を入力してください",
                },
                validate: (value) => !isNaN(value) || "数値を入力してください",
              })}
              error={!!errors.count}
              helperText={errors.count?.message}
              inputProps={{ inputMode: "numeric" }}
            />
          </DialogContent>
          <CustomDialogActions>
            <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
            <LoadingButton
              variant="contained"
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
              fullWidth
            >
              保存
            </LoadingButton>
          </CustomDialogActions>
        </form>
      </CustomDialog>
    </>
  )
}
