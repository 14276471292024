import { useMemo, useState, useEffect, ReactNode } from "react"

import { Add, Close } from "@mui/icons-material"
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  Card,
  Menu,
  MenuItem,
  IconButton,
  Chip,
  alpha,
} from "@mui/material"
import { useParams, useSearchParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"

import {
  Prize,
  PrizeFloorMapPoint,
  PrizeBoothShelf,
  PrizeOnBoothShelf,
  PrizeShelf,
  PrizeStorageElement,
} from "src/api/models"
import { getPrizeFloorMap } from "src/api/prize-floor-map"
import { getPrizeStorages } from "src/api/prize-storages"
import { getPrizes } from "src/api/prizes"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import {
  CardKeyCell,
  CardItemNameBox,
  CardValueCell,
  TableBorderedRow,
} from "src/components/molecules/CardTableCells"
import { CustomDialog } from "src/components/molecules/CustomDialog"
import { PrizeImageBox } from "src/components/molecules/PrizeImageBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { InventoryPrizeAddModal } from "src/components/organisms/prizes/InventoryPrizeAddModal"
import { StockFilter } from "src/components/organisms/StockFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { QrCodeReaderTemplate } from "src/components/templates/QrCodeReaderTemplate"
import {
  convertURLPathToPrizePlacementType,
  getPlacementShelfOrBoothLabel,
  getPlacementTypeLabel,
  PlacementType,
} from "src/domains/prizes/placementStatusRepository"
import { useResource } from "src/hooks/useResource"
import {
  datePickerDateLabelStateSelector,
  snackbarErrorMessageState,
  snackbarSuccessMessageState,
  stockSearchParamsState,
} from "src/recoil"
import { theme } from "src/theme"
import { getToday } from "src/utils"

export interface InventoryPrizeAddSearchProps {
  placementType: PlacementType
}

type PageState = "search" | "add"
type Page = {
  title: string
  caption: string
  filter?: ReactNode
  content: ReactNode
  onClickBackButton?: () => void
}

export const InventoryPrizeAddSearch: React.FC<
  InventoryPrizeAddSearchProps
> = ({ placementType }) => {
  const { arcadeCd } = useParams()
  const searchParams = useRecoilValue(stockSearchParamsState)

  const prizes = useResource({
    subject: "過去取扱景品一覧の取得",
    fetch: arcadeCd ? () => getPrizes(arcadeCd, searchParams) : undefined,
    recoilKey: `getPrizes:${arcadeCd}:${JSON.stringify(searchParams)}`,
  }).resource?.data.prizes

  const storages = useResource({
    subject: "保管場所リストの取得",
    skip: placementType !== PlacementType.Storage,
    fetch: arcadeCd ? () => getPrizeStorages(arcadeCd) : undefined,
    recoilKey: `getStorages:${arcadeCd}`,
  }).resource?.data.storages

  const floorMapPoints = useResource({
    subject: "ブースリストの取得",
    skip: placementType === PlacementType.Storage,
    fetch: arcadeCd ? () => getPrizeFloorMap(arcadeCd) : undefined,
    recoilKey: `getInventoryFloorMap:${arcadeCd}`,
  }).resource?.data.floorMapPoints

  const [selectedPrize, setSelectedPrize] = useState<Prize>()
  const [selectedStorageId, setSelectedStorageId] = useState<number | null>(
    null,
  )
  const [selectedPlacementId, setSelectedPlacementId] = useState<number | null>(
    null,
  )

  const [defaultSearchParams] = useSearchParams()
  useEffect(() => {
    const defaultStorageId = defaultSearchParams.get("defaultStorageId")
    const defaultPlacementId = defaultSearchParams.get("defaultPlacementId")
    const numberDefaultStorageId = !isNaN(Number(defaultStorageId))
      ? Number(defaultStorageId)
      : null
    const numberDefaultPlacementId = !isNaN(Number(defaultPlacementId))
      ? Number(defaultPlacementId)
      : null
    setSelectedStorageId(numberDefaultStorageId)
    setSelectedPlacementId(numberDefaultPlacementId)
  }, [defaultSearchParams])

  const [pageState, setPageState] = useState<PageState>("search")
  const onSelect = (prize: React.SetStateAction<Prize | undefined>) => {
    setSelectedPrize(prize)
    setPageState("add")
  }
  const onClose = () => {
    setSelectedPrize(undefined)
    setPageState("search")
  }
  let page = {} as Page
  if (pageState === "search") {
    page = {
      title: "過去取扱景品から登録",
      caption: "景品を検索したり、景品をブースに追加することができます。",
      filter: <StockFilter />,
      content: (
        <InventoryPrizeAddTable prizes={prizes || []} onSelect={onSelect} />
      ),
    }
  }
  if (pageState === "add" && selectedPrize) {
    page = {
      title: "景品詳細",
      caption:
        "景品の詳細情報を確認したり、ブースに景品を追加することができます。",
      content: (
        <InventoryPrizeAdd
          prize={selectedPrize}
          onClose={onClose}
          placementType={placementType}
          storages={storages}
          floorMapPoints={floorMapPoints}
          selectedStorageId={selectedStorageId}
          selectedPlacementId={selectedPlacementId}
        />
      ),
      onClickBackButton: onClose,
    }
  }

  return (
    <MainContentLayout
      title={page.title}
      caption={page.caption}
      renderAction={() => (
        <InventoryPrizeAddPlacementSelect
          {...{
            placementType,
            storages,
            floorMapPoints,
            selectedStorageId,
            setSelectedStorageId,
            selectedPlacementId,
            setSelectedPlacementId,
          }}
        />
      )}
      renderFilter={page.filter ? () => page.filter : undefined}
      renderContent={() => page.content}
    />
  )
}

interface InventoryPrizeAddTableProps {
  prizes: Prize[]
  onSelect: React.Dispatch<React.SetStateAction<Prize | undefined>>
}

const InventoryPrizeAddTable: React.FC<InventoryPrizeAddTableProps> = ({
  prizes,
  onSelect,
}: InventoryPrizeAddTableProps) => {
  return (
    <PaginatedTable
      noMargin
      stateKey="inventoryPrizeAddTable"
      items={prizes}
      renderRow={(prize) => (
        <TableBorderedRow
          key={prize.prizeCd}
          sx={{
            "&:hover": {
              cursor: "pointer",
              td: { background: (theme) => theme.palette.neutral[200] },
            },
          }}
          onClick={() => {
            onSelect(prize)
            // NOTE: 景品選択後にブース選択しやすくするため、ページ先頭にスクロール
            window.scrollTo(0, 0)
          }}
        >
          <ExtTableCell sx={{ p: 2, width: 76 }}>
            <Box width={64} height={64}>
              <PrizeImageBox prizeCd={prize.prizeCd} alt={prize.prizeName} />
            </Box>
          </ExtTableCell>
          <ExtTableCell sx={{ p: 2, paddingLeft: 0 }}>
            <CardItemNameBox>{prize.prizeName}</CardItemNameBox>
            <Typography
              color="text.secondary"
              variant="caption"
              sx={{ pt: 0.5 }}
            >
              {prize.prizeCd}
            </Typography>
          </ExtTableCell>
        </TableBorderedRow>
      )}
    />
  )
}

interface InventoryPrizeAddProps {
  prize: Prize
  onClose: () => void
  placementType: PlacementType
  storages?: PrizeStorageElement[]
  floorMapPoints?: PrizeFloorMapPoint[]
  selectedStorageId: number | null
  selectedPlacementId: number | null
}

const InventoryPrizeAdd: React.FC<InventoryPrizeAddProps> = ({
  prize,
  onClose,
  placementType,
  storages,
  floorMapPoints,
  selectedStorageId,
  selectedPlacementId,
}) => {
  const [showModal, setShowModal] = useState(false)
  const setDatePickerDateLabel = useSetRecoilState(
    datePickerDateLabelStateSelector,
  )
  const { prizeCd, prizeName, makerName, ipName, unitPerCarton, unitPriceJpy } =
    prize

  const listItems = [
    {
      key: "景品CD",
      value: prizeCd,
    },
    {
      key: "景品名",
      value: prizeName,
    },
    {
      key: "メーカー",
      value: makerName,
    },
    {
      key: "IP名",
      value: ipName,
    },
    {
      key: "カートン入数",
      value: unitPerCarton.toLocaleString(),
    },
    unitPriceJpy && {
      key: "単価",
      value: unitPriceJpy ? `${unitPriceJpy.toLocaleString()}円` : "-",
    },
  ].filter((item) => !!item)

  return (
    <>
      <Box>
        <Card sx={{ mb: 2 }}>
          <Table>
            <TableBody>
              <TableBorderedRow>
                <ExtTableCell colSpan={2}>
                  <Box sx={{ maxWidth: "90%", margin: "auto" }}>
                    <PrizeImageBox
                      prizeCd={prizeCd}
                      alt={prizeName}
                      noImageSize="large"
                    />
                  </Box>
                </ExtTableCell>
              </TableBorderedRow>

              {listItems.map(
                (item) =>
                  item && (
                    <TableBorderedRow key={item.key}>
                      <CardKeyCell>{item.key}</CardKeyCell>
                      <CardValueCell>{item.value}</CardValueCell>
                    </TableBorderedRow>
                  ),
              )}
            </TableBody>
          </Table>
        </Card>

        <Button
          variant="contained"
          sx={{ mb: 2 }}
          fullWidth
          onClick={() => {
            setShowModal(true)
            // NOTE: 景品検索から登録モーダル遷移時は登録日付を今日にする
            setDatePickerDateLabel(getToday())
          }}
        >
          {!!selectedPlacementId && "選択している"}
          {getPlacementTypeLabel(placementType)}
          に景品追加
        </Button>
      </Box>

      <InventoryPrizeAddModal
        showModal={showModal}
        prize={prize}
        onClose={() => setShowModal(false)}
        onFinish={() => {
          setShowModal(false)
          onClose()
        }}
        placementType={placementType}
        storages={storages}
        floorMapPoints={floorMapPoints}
        selectedStorageId={selectedStorageId}
        selectedPlacementId={selectedPlacementId}
      />
    </>
  )
}

interface InventoryPrizeAddPlacementSelectProps {
  placementType: PlacementType
  storages?: PrizeStorageElement[]
  floorMapPoints?: PrizeFloorMapPoint[]
  selectedStorageId: number | null
  setSelectedStorageId: React.Dispatch<React.SetStateAction<number | null>>
  selectedPlacementId: number | null
  setSelectedPlacementId: React.Dispatch<React.SetStateAction<number | null>>
}

const InventoryPrizeAddPlacementSelect: React.FC<
  InventoryPrizeAddPlacementSelectProps
> = ({
  placementType,
  storages = [],
  floorMapPoints = [],
  selectedStorageId,
  setSelectedStorageId,
  selectedPlacementId,
  setSelectedPlacementId,
}) => {
  const { arcadeCd } = useParams()
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)
  const setSuccessMessage = useSetRecoilState(snackbarSuccessMessageState)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  const parseQrCodePath = (path: string) => {
    const params = path
      .split("/")
      .filter(
        (item) =>
          !["", "arcades", "inventory", "prizes", "placements"].includes(item),
      )
    const [arcadeCd, placementTypeStr, placementIdStr] = params
    const placementType = convertURLPathToPrizePlacementType(placementTypeStr)
    let placementId = Number(placementIdStr)
    if (placementType === "storage" && !path.includes("inventory")) {
      // NOTE: 現URLの場合、params[2] は storageId になるので調整
      placementId = Number(params[3])
    }
    return {
      arcadeCd,
      placementType,
      placementId,
    }
  }

  const [showQrCodeModal, setShowQrCodeModal] = useState(false)
  const onClickQrCode = () => setShowQrCodeModal(true)
  const onCloseQrCodeModal = () => {
    setShowQrCodeModal(false)
    setAnchorEl(null)
  }
  const onFinishQrCodeModal = (path: string) => {
    const parsedQr = parseQrCodePath(path)
    if (parsedQr.arcadeCd !== arcadeCd) {
      return setErrorMessage("他店舗の二次元コードです")
    }
    if (parsedQr.placementType !== placementType) {
      return setErrorMessage(
        `${getPlacementTypeLabel(placementType)}の二次元コードではありません`,
      )
    }

    let selectedStorage: PrizeStorageElement | undefined = undefined
    let selectedPlacement:
      | PrizeShelf
      | PrizeBoothShelf
      | PrizeOnBoothShelf
      | undefined = undefined
    if (placementType === PlacementType.Storage) {
      selectedStorage = storages.find((storage) =>
        storage.shelves?.find((shelf) => shelf?.id === parsedQr.placementId),
      )
      selectedPlacement = selectedStorage?.shelves?.find(
        (shelf) => shelf?.id === parsedQr.placementId,
      )
    }
    if (placementType === PlacementType.InBooth) {
      selectedPlacement = floorMapPoints.find(
        (point) => point.boothShelf.id === parsedQr.placementId,
      )?.boothShelf
    }
    if (placementType === PlacementType.OnBooth) {
      selectedPlacement = floorMapPoints.find(
        (point) => point.onBoothShelf.id === parsedQr.placementId,
      )?.onBoothShelf
    }

    if (!selectedPlacement?.isAvailable) {
      return setErrorMessage(
        `${getPlacementShelfOrBoothLabel(
          placementType,
        )}が存在しないか、無効です`,
      )
    }
    setSelectedStorageId(selectedStorage?.storage.id || null)
    setSelectedPlacementId(selectedPlacement?.id || null)
    setSuccessMessage("二次元コードの読み取りに成功しました")
    onCloseQrCodeModal()
  }

  const selectedPlacementName = useMemo(() => {
    if (placementType === PlacementType.Storage) {
      const storage = storages.find(
        (storage) => storage.storage.id === selectedStorageId,
      )
      const shelf = storage?.shelves?.find(
        (shelf) => shelf?.id === selectedPlacementId,
      )

      if (!storage || !shelf) return ""
      return `${storage?.storage.name} ${shelf?.name}`
    }
    if (placementType === PlacementType.InBooth) {
      const floorMapPoint = floorMapPoints.find(
        (point) => point.boothShelf.id === selectedPlacementId,
      )
      return floorMapPoint?.name || ""
    }
    if (placementType === PlacementType.OnBooth) {
      const floorMapPoint = floorMapPoints.find(
        (point) => point.onBoothShelf.id === selectedPlacementId,
      )
      return floorMapPoint?.name || ""
    }
    return ""
  }, [
    storages,
    floorMapPoints,
    placementType,
    selectedPlacementId,
    selectedStorageId,
  ])

  const onDeleteSelectedPlacement = () => {
    setSelectedStorageId(null)
    setSelectedPlacementId(null)
  }

  if (selectedPlacementId && selectedPlacementName) {
    return (
      <Chip
        label={selectedPlacementName}
        variant="filled"
        onDelete={() => onDeleteSelectedPlacement()}
        sx={{
          background: alpha(theme.palette.primary.light, 0.3),
          fontWeight: "bold",
        }}
      />
    )
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Add />}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {getPlacementShelfOrBoothLabel(placementType)}を選択
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => onClickQrCode()}>
          二次元コードから読み取り
        </MenuItem>
      </Menu>

      <CustomDialog
        fullWidth
        maxWidth="sm"
        open={showQrCodeModal}
        onClose={() => onCloseQrCodeModal()}
        scroll="paper"
      >
        <IconButton
          aria-label="close"
          onClick={() => onCloseQrCodeModal()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <QrCodeReaderTemplate
          onFinish={onFinishQrCodeModal}
          showBackButton={false}
        />
      </CustomDialog>
    </>
  )
}
