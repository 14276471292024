import { useState } from "react"

import { useSetRecoilState } from "recoil"

import { Csv } from "src/api/models"
import { snackbarErrorMessageState } from "src/recoil"
import { parseCsvFile } from "src/utils/parseCsvFile"

type UseUploadCsvProps = {
  requiredColumns: readonly string[]
  allowEmptyRows?: boolean
}

export const useUploadCsv = ({
  requiredColumns,
  allowEmptyRows = false,
}: UseUploadCsvProps) => {
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const [uploadedCsv, setUploadedCsv] = useState<Csv>()

  const onUploadCsv = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (!files) {
      return setErrorMessage("ファイルが取得できませんでした")
    }
    const file = files[0]
    if (!file) {
      return setErrorMessage("ファイルが取得できませんでした")
    }

    const { headerRow, rows } = await parseCsvFile(file)

    const missingColumn = requiredColumns.find(
      (column) => !headerRow.columns.includes(column),
    )
    if (missingColumn) {
      e.target.value = ""
      setErrorMessage(
        `CSVファイルから「${missingColumn}」列を検出できませんでした`,
      )
      return
    }
    if (!allowEmptyRows && rows.length === 0) {
      e.target.value = ""
      setErrorMessage(`CSVファイルのデータが空です`)
      return
    }
    setUploadedCsv({
      headerRow,
      rows,
    })
  }

  const resetUploadedCsv = () => setUploadedCsv(undefined)

  return { uploadedCsv, onUploadCsv, resetUploadedCsv }
}
