import {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useImperativeHandle,
} from "react"

import {
  Fullscreen,
  FullscreenExit,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material"
import {
  alpha,
  Box,
  Card,
  Slider,
  IconButton,
  SxProps,
  Checkbox,
  Stack,
} from "@mui/material"
import { usePinch } from "@use-gesture/react"
import Selecto from "react-selecto"
import { useRecoilState, useRecoilValue } from "recoil"

import { PrizeToneBoothInfo } from "src/api/models"
import { calcDailySalesStats } from "src/domains/prizes/boothRepository"
import {
  BaseFloorMapPoint,
  PrizePlanWithSaleFloorMapPoint,
} from "src/domains/prizes/floorMapRepository"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import { useFloorMap } from "src/hooks/useFloorMap"
import {
  floorMapFullscreenState,
  floorMapMultiSelectState,
  floorMapPointInfoBoxSizeState,
} from "src/recoil"
import { theme } from "src/theme"
import {
  filterUniqueArray,
  getBaseLog,
  getRatioLabel,
  roundNearest100,
} from "src/utils"

const adjustLinefeedForPointName = (pointName: string) =>
  pointName.split("-").map((part, i, textArr) =>
    // 改行可能位置をブラウザに教える
    textArr.length >= 2 && i === textArr.length - 2 ? (
      <Fragment key={part}>
        {part}
        -
        <wbr />
      </Fragment>
    ) : i === textArr.length - 1 ? (
      part
    ) : (
      `${part}-`
    ),
  )

export interface FloorMapBoxProps {
  floorMapPoints: BaseFloorMapPoint[]
  shouldScroll?: (point: BaseFloorMapPoint) => boolean
  onClickPoint?: (point: BaseFloorMapPoint) => void
  onSelectPoints?: (pointIds: number[]) => void
  getFloorMapPointBox?: (baseProps: FloorMapPointBoxProps) => React.ReactNode
  renderDescription?: () => React.ReactNode
  showMultiSelectButton?: boolean
}

export interface FloorMapBoxHandler {
  selectAll: () => void
  deselectAll: () => void
}

export const FloorMapBox = forwardRef<FloorMapBoxHandler, FloorMapBoxProps>(
  function FloorMapBox(
    {
      floorMapPoints,
      shouldScroll,
      onClickPoint = () => undefined,
      onSelectPoints,
      getFloorMapPointBox = ({ point }) => (
        <FloorMapPointBox key={point.id} {...{ point, onClickPoint }} />
      ),
      renderDescription,
      showMultiSelectButton = false,
    },
    ref,
  ) {
    const { isPc } = useBreakpoints()
    const cardRef = useRef<HTMLDivElement>(null)
    const { floorMapSize, zoomRatio, zoomRange, onChangeSlider } = useFloorMap(
      cardRef,
      floorMapPoints,
      shouldScroll,
    )
    const isFullscreen = useRecoilValue(floorMapFullscreenState)
    const [isMultiSelect, setIsMultiSelect] = useRecoilState(
      floorMapMultiSelectState,
    )

    const selectoRef = useRef<Selecto | null>(null)

    // onSelectPoints を呼ぶだけでは Selecto の状態が更新されないため ref で外部から操作できるようにする
    useImperativeHandle(
      ref,
      () => ({
        selectAll() {
          if (!onSelectPoints || !selectoRef.current) return

          const selectableElements = selectoRef.current.getSelectableElements()
          selectoRef.current.setSelectedTargets(selectableElements)
          onSelectPoints(
            selectableElements
              .map((el) => Number(el.dataset.pointId))
              .filter(Boolean),
          )
        },
        deselectAll() {
          if (!onSelectPoints || !selectoRef.current) return

          selectoRef.current.setSelectedTargets([])
          onSelectPoints([])
        },
      }),
      [onSelectPoints],
    )

    const gestureRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const gesturePreventDefault = (e: Event) => e.preventDefault()
      const touchPreventDefault = (e: TouchEvent) => {
        if (e.touches.length > 1) {
          e.preventDefault()
        }
      }
      const element = gestureRef.current

      // NOTE: Safari のブラウザ側ズームを防止する処理
      // ref: https://use-gesture.netlify.app/docs/gestures/#about-the-pinch-gesture
      document.addEventListener("gesturestart", gesturePreventDefault, false)
      document.addEventListener("gesturechange", gesturePreventDefault, false)
      // NOTE: Android の Chrome でブラウザ側ズームを防止する処理
      document.addEventListener("touchstart", touchPreventDefault, false)
      element?.addEventListener("touchstart", touchPreventDefault, false)
      return () => {
        document.removeEventListener(
          "gesturestart",
          gesturePreventDefault,
          false,
        )
        document.removeEventListener(
          "gesturechange",
          gesturePreventDefault,
          false,
        )
        document.removeEventListener("touchstart", touchPreventDefault, false)
        element?.removeEventListener("touchstart", touchPreventDefault, false)
      }
    }, [])

    usePinch(
      ({ offset }) => {
        // NOTE: 0 〜 100 の newZoomRange に変換
        const newZoomRange = getBaseLog(10, offset[0]) * 50 + 50
        if (newZoomRange !== zoomRange) {
          onChangeSlider(newZoomRange)
        }
      },
      {
        target: gestureRef,
        from: ({ offset }) => [10 ** ((zoomRange - 50) / 50), offset[1]],
        scaleBounds: { min: 0.1, max: 10 },
        pointer: {
          touch: true,
        },
      },
    )

    const memoFloorMapPoints = useMemo(
      () => (
        <>
          {floorMapPoints.map((point) => (
            <FloorMapPointWrapper
              key={point.id}
              {...{ point, getFloorMapPointBox }}
            />
          ))}
        </>
      ),
      [floorMapPoints, getFloorMapPointBox],
    )

    const memoTopStickyBox = useMemo(
      () => (
        <Box
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            pointerEvents: "none",
          }}
        >
          {showMultiSelectButton && (
            <Box
              sx={{
                mt: 1,
                ml: 1,
                display: "flex",
                alignItems: "center",
                height: 20,
                borderRadius: 10,
                background: alpha(theme.palette.neutral[200], 0.5),
                fontWeight: "bold",
                fontSize: 7,
                cursor: "pointer",
                pl: 0.5,
                pr: 1,
                pointerEvents: "auto",
              }}
              onClick={() => setIsMultiSelect(!isMultiSelect)}
            >
              <Checkbox
                checked={isMultiSelect}
                sx={{ transform: "scale(0.5)", width: 20, height: 20 }}
                size="small"
              />
              複数選択
            </Box>
          )}

          {/* spacer */}
          <Box sx={{ flexGrow: 1, pointerEvents: "none" }} />

          <FloorMapFullscreenButton />
        </Box>
      ),
      [isMultiSelect, setIsMultiSelect, showMultiSelectButton],
    )

    return (
      <Box
        sx={{
          ...(isFullscreen
            ? {
                position: "fixed",
                left: isPc ? 280 : 0,
                top: 64,
                width: isPc ? "calc(100% - 280px)" : "100%",
                height: "calc(100% - 64px)",
                display: "flex",
                flexDirection: "column",
                background: theme.palette.background.default,
                zIndex: 10,
              }
            : {
                width: "100%",
              }),
        }}
        ref={gestureRef}
      >
        <Box
          sx={{
            width: "100%",
            height: "48px",
            mb: 1,
            display: "flex",
            alignItems: "center",
            userSelect: "none",
          }}
        >
          <Box>
            <IconButton
              disableRipple
              onClick={() => onChangeSlider(zoomRange - 10)}
              sx={{
                color: "primary.main",
                backgroundColor: "white",
              }}
            >
              <ZoomOut fontSize="inherit" />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              mx: 2,
              display: "flex",
              alignItems: "center",
              userSelect: "none",
            }}
          >
            <Slider
              value={zoomRange}
              min={2}
              step={2}
              onChange={(_, value) => onChangeSlider(Number(value))}
            />
          </Box>

          <Box>
            <IconButton
              disableRipple
              onClick={() => onChangeSlider(zoomRange + 10)}
              sx={{
                color: "primary.main",
                backgroundColor: "white",
              }}
            >
              <ZoomIn fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>

        {renderDescription && <Box sx={{ mb: 2 }}>{renderDescription()}</Box>}

        <Card
          sx={{
            position: "relative",
            p: 0.5,
            width: "100%",
            overflow: "scroll",
            ...(isFullscreen
              ? {
                  flexGrow: 1,
                }
              : {
                  height: "50vh",
                }),
          }}
          ref={cardRef}
          data-testid="map-points-card"
        >
          {/* NOTE: transform: scale() を使うと Chrome で拡大時にマップ右下がスクロール範囲からはみ出るため、対策として領域確保 */}
          <Box
            width={floorMapSize.x * zoomRatio}
            height={floorMapSize.y * zoomRatio}
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
          {onSelectPoints && (
            // README: https://github.com/daybrush/selecto/tree/master/packages/react-selecto#-how-to-use
            // DOCS: https://daybrush.com/selecto/release/latest/doc/index.html
            <Selecto
              ref={(ref) => {
                // ref が Selecto インスタンスの型になっているが実際はコンポーネントなのでインスタンスを取り出す
                selectoRef.current =
                  ref && (ref as unknown as { selecto: Selecto }).selecto
              }}
              selectableTargets={["div[data-point-id]"]}
              // onClickPoint が指定されている場合はクリックで選択しない
              selectByClick={false}
              selectFromInside={true}
              // Shift キーを押しながら選択追加、さらに Crtl キーを押しながら選択解除
              // https://github.com/daybrush/selecto/blob/master/packages/storybook/stories/1-selecto/apps/ContinueSelectKeyWithDeselect.tsx#L29-L35
              continueSelect={false}
              continueSelectWithoutDeselect={true}
              toggleContinueSelect={"shift"}
              toggleContinueSelectWithoutDeselect={[["ctrl"], ["meta"]]}
              hitRate={1}
              // 範囲選択時のスクロール追従
              // https://daybrush.com/selecto/storybook/?path=/story/selecto--select-in-the-scroll-area
              scrollOptions={{
                container: cardRef,
                throttleTime: 30,
                threshold: 0,
              }}
              onScroll={(e) => {
                cardRef.current?.scrollBy(
                  e.direction[0] * 10,
                  e.direction[1] * 10,
                )
              }}
              onSelect={(e) => {
                // 選択中の要素の data-point-id を取得し onSelectPoints に渡す
                const pointIds = e.selected
                  .map((el) => Number(el.dataset.pointId))
                  .filter(Boolean)
                onSelectPoints(pointIds)
              }}
            />
          )}
          <Box
            sx={{
              transform: `scale(${zoomRatio})`,
              transformOrigin: "0 0",
              position: "relative",
              // ドラッグ時にテキストが選択されないようにする
              userSelect: "none",
            }}
            data-testid="map-points-container"
          >
            {memoFloorMapPoints}
          </Box>

          {memoTopStickyBox}
        </Card>
      </Box>
    )
  },
)

type FloorMapPointWrapperProps = {
  point: BaseFloorMapPoint
  getFloorMapPointBox: Exclude<
    FloorMapBoxProps["getFloorMapPointBox"],
    undefined
  >
}

const FloorMapPointWrapper: React.FC<FloorMapPointWrapperProps> = ({
  point,
  getFloorMapPointBox,
}: FloorMapPointWrapperProps) => {
  return useMemo(
    () => <>{getFloorMapPointBox({ point })}</>,
    [getFloorMapPointBox, point],
  )
}

const nameGradation =
  "-webkit-gradient(linear, 0% 80%, 0% 100%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))"

export interface FloorMapPointBoxProps {
  point: BaseFloorMapPoint
  offset?: { x?: number; y?: number }
  children?: React.ReactNode
  onClickPoint?: FloorMapBoxProps["onClickPoint"]
  sx?: SxProps
}

export const FloorMapPointBox: React.FC<FloorMapPointBoxProps> = ({
  point,
  offset,
  children,
  onClickPoint = () => undefined,
  sx = {},
}: FloorMapPointBoxProps) => {
  const { topLeftX, topLeftY, bottomRightX, bottomRightY } = point
  const left = topLeftX + (offset?.x ?? 0)
  const top = topLeftY + (offset?.y ?? 0)
  const width = bottomRightX - topLeftX
  const height = bottomRightY - topLeftY

  const borderRadius = 2

  return (
    <Box
      sx={{
        position: "absolute",
        left,
        top,
        width,
        height,
        border: `1px solid ${theme.palette.neutral[400]}`,
        borderRadius,
        cursor: "pointer",
        ...sx,
      }}
      onClick={() => onClickPoint(point)}
      data-testid={`floor-map-point-${point.id}`}
    >
      {children || <FloorMapPointNameBox {...{ point }} />}
    </Box>
  )
}

interface FloorMapPointNameBoxProps {
  point: BaseFloorMapPoint
  sx?: SxProps
}

export const FloorMapPointNameBox: React.FC<FloorMapPointNameBoxProps> = ({
  point,
  sx,
}: FloorMapPointNameBoxProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 20,
        lineHeight: 1.1,
        wordBreak: "keep-all",
        overflowWrap: "break-word",
        WebkitMaskImage: nameGradation,
        ...sx,
      }}
    >
      <Box sx={{ width: "100%", maxHeight: "100%", p: 0.5 }}>
        {adjustLinefeedForPointName(point.name)}
      </Box>
    </Box>
  )
}

export const floorMapPointInfoBoxThreshold = 40
export type FloorMapPointInfoBoxSize = "large" | "small"

interface FloorMapPointInfoBoxProps {
  point: BaseFloorMapPoint
  pointBooths: PrizeToneBoothInfo[]
}

export const FloorMapPointInfoBox: React.FC<FloorMapPointInfoBoxProps> = ({
  point,
  pointBooths,
}: FloorMapPointInfoBoxProps) => {
  const size = useRecoilValue(floorMapPointInfoBoxSizeState)
  const uniquePrizeNames = useMemo(
    () => pointBooths.map((b) => b.prizeName).filter(filterUniqueArray),
    [pointBooths],
  )

  const memoBoxLarge = useMemo(
    () => (
      <FloorMapPointInfoBoxLarge
        {...{ point, pointBooths, uniquePrizeNames }}
      />
    ),
    [point, pointBooths, uniquePrizeNames],
  )

  const memoBoxSmall = useMemo(
    () => <FloorMapPointInfoBoxSmall {...{ uniquePrizeNames }} />,
    [uniquePrizeNames],
  )

  if (size == "large") {
    return memoBoxLarge
  }
  return memoBoxSmall
}

interface FloorMapPointInfoBoxLargeProps {
  point: BaseFloorMapPoint
  pointBooths: PrizeToneBoothInfo[]
  uniquePrizeNames: string[]
}

export const FloorMapPointInfoBoxLarge: React.FC<
  FloorMapPointInfoBoxLargeProps
> = ({
  point,
  pointBooths,
  uniquePrizeNames,
}: FloorMapPointInfoBoxLargeProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        fontWeight: "bold",
        fontSize: 13,
        lineHeight: 1.1,
        wordBreak: "break-all",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          flexGrow: 1,
        }}
      >
        <FloorMapPointPrizeNames prizeNames={uniquePrizeNames} />
        {calcDailySalesStats(pointBooths) > 0 && (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              bottom: 0,
              color: theme.palette.error.main,
              background: alpha("#FFFFFF", 0.75),
              fontSize: 15,
              p: 0.3,
            }}
          >
            {roundNearest100(calcDailySalesStats(pointBooths)).toLocaleString()}
            円
          </Box>
        )}
      </Box>
      <Box
        sx={{
          background: alpha(theme.palette.neutral[900], 0.9),
          color: "white",
          width: "100%",
          px: 1,
          py: 0.2,
          textAlign: "center",
          wordBreak: "keep-all",
          overflowWrap: "break-word",
        }}
      >
        {adjustLinefeedForPointName(point.name)}
      </Box>
    </Box>
  )
}

interface FloorMapPointInfoBoxSmallProps {
  uniquePrizeNames: string[]
}

export const FloorMapPointInfoBoxSmall: React.FC<
  FloorMapPointInfoBoxSmallProps
> = ({ uniquePrizeNames }: FloorMapPointInfoBoxSmallProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        fontWeight: "bold",
        fontSize: 17,
        lineHeight: 1.1,
        wordBreak: "break-all",
      }}
    >
      <FloorMapPointPrizeNames prizeNames={uniquePrizeNames} />
    </Box>
  )
}

interface FloorMapPointPrizeNamesProps {
  prizeNames: string[]
}

const FloorMapPointPrizeNames: React.FC<FloorMapPointPrizeNamesProps> = ({
  prizeNames,
}: FloorMapPointPrizeNamesProps) => {
  const size = useRecoilValue(floorMapPointInfoBoxSizeState)
  return (
    <>
      {prizeNames.map((prizeName) => (
        <Box
          key={prizeName}
          sx={{
            display: "flex",
            alignItems: size === "large" ? "flex-start" : "center",
            justifyContent: "center",
            overflow: "hidden",
            height: `${100 / prizeNames.length}%`,
            WebkitMaskImage: nameGradation,
          }}
        >
          <Box sx={{ width: "100%", maxHeight: "100%", p: 0.5 }}>
            {prizeName}
          </Box>
        </Box>
      ))}
    </>
  )
}

const FloorMapFullscreenButton: React.FC = () => {
  const [isFullscreen, setIsFullscreen] = useRecoilState(
    floorMapFullscreenState,
  )

  return (
    <IconButton
      disableRipple
      onClick={() => setIsFullscreen(!isFullscreen)}
      sx={{
        display: "flex",
        flexDirection: "column",
        color: theme.palette.neutral[600],
        background: alpha("#FFFFFF", 0.6),
        p: 0,
        width: 44,
        height: 44,
        pointerEvents: "auto",
      }}
    >
      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      <Box sx={{ fontSize: 7, fontWeight: "bold" }}>
        {isFullscreen ? "全画面解除" : "全画面"}
      </Box>
    </IconButton>
  )
}

export interface PrizeDailySalesFloorMapPointBoxProps
  extends FloorMapPointBoxProps {
  fontSize?: number
}

export const PrizeDailySalesFloorMapPointBox: React.FC<
  PrizeDailySalesFloorMapPointBoxProps
> = ({ point, fontSize = 10 }: PrizeDailySalesFloorMapPointBoxProps) => {
  const { plans, prizeDailySale } = point as PrizePlanWithSaleFloorMapPoint
  const isChanged = plans.some(
    (plan) =>
      plan.isPrizePlanChanged ||
      plan.isSettingChanged ||
      plan.isBoothCategoryChanged,
  )

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        wordBreak: "break-all",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          overflow: "hidden",
          flexGrow: 1,
          background: isChanged ? theme.palette.sub.cyanLight : "white",
        }}
      >
        {plans.map((plan) => (
          <Box
            key={plan.id}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              overflow: "hidden",
              maxHeight: `${100 / plans.length}%`,
              fontWeight: "bold",
              fontSize: fontSize,
              lineHeight: 1.1,
              p: 0.5,
              ...(isChanged && {
                color: theme.palette.sub.cyan,
              }),
            }}
          >
            {(plan.setting !== "" ? plan.setting + "：" : "") +
              plan.prize.prizeName}
          </Box>
        ))}
      </Box>
      {prizeDailySale && (
        <Stack
          sx={(theme) => ({
            background: theme.palette.primary.light,
            width: "100%",
            px: 0,
            py: 0.5,
            textAlign: "center",
            wordBreak: "keep-all",
            overflowWrap: "break-word",
            fontWeight: "bold",
            fontSize: fontSize,
            lineHeight: 1.1,
            flexDirection: "row",
            justifyContent: "space-evenly",
          })}
        >
          <Stack>
            {prizeDailySale?.sales !== undefined
              ? `${prizeDailySale.sales.toLocaleString()}円`
              : "-"}
          </Stack>
          <Stack>
            {prizeDailySale
              ? `${getRatioLabel(prizeDailySale.payoutRate)}`
              : "-"}
          </Stack>
        </Stack>
      )}
      <Box
        sx={{
          background: alpha(theme.palette.neutral[900], 0.9),
          color: "white",
          width: "100%",
          px: 1,
          py: 0,
          textAlign: "center",
          wordBreak: "keep-all",
          overflowWrap: "break-word",
          fontWeight: "bold",
          fontSize: fontSize,
          lineHeight: 1.1,
        }}
      >
        {adjustLinefeedForPointName(point.name)}
      </Box>
    </Box>
  )
}
